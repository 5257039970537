<template>
    <BasicModal
        :show="isAddModalOpen"
        @close="cancelAdd"
        hideCloseButton
    >
        <AddAccountForm
            :source="accountToAdd"
            :isEditing="true"
            :isLoading="isLoading"
            :debug="isDebug"
            @cancel="cancelAdd"
            @submit="submitAdd"
        />
    </BasicModal>
    <BasicModal
        :show="isEditModalOpen"
        @close="cancelEdit"
        hideCloseButton
    >
        <EditAccountForm
            :source="accountToEdit"
            :isEditing="true"
            :isLoading="isLoading"
            :debug="isDebug"
            @cancel="cancelEdit"
            @submit="submitEdit"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Account"
        confirmationText="Are you sure you want to delete this account? This action cannot be undone."
        :open="isDeleteModalOpen"
        @close="cancelDelete"
        @cancel="cancelDelete"
        @delete="submitDelete"
    />

    <AdminContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        Edit, delete, and add accounts.
                    </PageDescription>
                    <ModalButton
                        theme="primary"
                        label="Add Account"
                        :busy="isLoading"
                        :disabled="isAddButtonDisabled"
                        @click.prevent="clickAddButton"
                    />
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="pagination.enabled"
                        :paginationPageSize="pagination.size"
                        :columnDefs="colDefs"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                        overlayNoRowsTemplate="No accounts to display."
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </AdminContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="alerts.state.alerts.value"
            @dismiss:alert="alerts.methods.dismissAlert($event)"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted, computed } from 'vue';
    import { useStore } from 'vuex';
    import { computedEager } from '@vueuse/core';

    // <!-- TYPES -->
    /** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
    import { ECNBState } from '@/store/types/ECNBStore';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageWrapper from '@components/PageWrapper.vue';
    import PageIntro from '@components/PageIntro.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import PageDescription from '@components/PageDescription.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import AdminContainer from '~Admin/components/wrappers/AdminContainer.vue';
    import AddAccountForm from '~AccountManager/components/AddAccountForm.vue';
    import EditAccountForm from '~AccountManager/components/EditAccountForm.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- COMPOSABLES -->
    import { useAccountManager } from '~AccountManager/hooks/useAccountManager';
    import { useNARAFeature } from '@/utils/features';
    import { useExpiredSubscriptionGate } from '@/hooks/gates';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Accounts',
        components: {
            AgGridVue,
            LoadingWrapper,
            PageWrapper,
            PageIntro,
            PageDescription,
            ModalButton,
            AdminContainer,
            BasicModal,
            BaseAlert,
            AddAccountForm,
            EditAccountForm,
            ConfirmDeleteModal,
        },
        setup(props, context) {
            /** Debug environment flag. */
            const isDebug = process.env.NODE_ENV !== 'production';

            // ==== COMPOSABLES ====
            const { constants, state, methods, alerts } = useAccountManager();
            const { isNARADisabled } = useNARAFeature();
            const { isSuperUser, isCurrentSubscriptionExpired } =
                useExpiredSubscriptionGate();

            // ==== CONSTANTS ====
            const defaultColDef = { ...constants.DefaultColumnDefinition };

            /** @type {Vuex.Store<ECNBState>} */
            const store = useStore();

            // ==== STATE ====
            const { targets, rowData, colDefs } = state;

            // Define the current organization.
            const currentOrganization = computed(() => {
                return store.state?.accounts?.organization;
            });

            const currentSubscription = computedEager(() => {
                const organization = currentOrganization.value;
                return organization?.currentSubscription;
            });

            const pendingSubscription = computedEager(() => {
                const organization = currentOrganization.value;
                return organization?.pendingSubscription;
            });

            const currentUser = computed(() => {
                return store.state?.users.me;
            });

            // Check if this is an enterprise account.
            const isOnEnterprisePlan = computed(() => {
                return currentOrganization?.value.currentSubscription?.plan
                    ?.isEnterprisePlan;
            });

            // Check if this is the NARA environment.
            const isAddButtonDisabled = computed(() => {
                const isSuper = isSuperUser.value === true;
                const isCommercial = isNARADisabled.value === true;
                const isEnterprise = isOnEnterprisePlan.value == true;
                const isExpired =
                    isCommercial && isCurrentSubscriptionExpired.value;
                const isDisabled = !isEnterprise || isExpired;

                // Handle resolution based on super admin status.
                return isSuper ? false : isDisabled;
            });

            // ==== COMPUTED PROPERTIES ====
            const {
                isLoading,
                isAddModalOpen,
                isEditModalOpen,
                isDeleteModalOpen,
            } = state;

            // ==== EVENTS ====
            const {
                onGridReady,
                onColumnResized,
                clickAddButton,
                cancelAdd,
                cancelEdit,
                cancelDelete,
                submitAdd,
                submitEdit,
                submitDelete,
            } = methods;

            // ==== SETUP ====
            colDefs.value = methods.getColumnDefs();

            /** @type {{ enabled: Boolean, size: Number }} */
            const pagination = { enabled: true, size: 25 };

            // ==== LIFECYCLE ====
            onMounted(async () => {
                // Refresh the data for the table.
                await methods.refreshIndex();
                console.dir(
                    `[add::account] ${isAddButtonDisabled.value !== true}`
                );
            });

            // ==== EXPOSE ====
            return {
                // SERVICES
                alerts,
                // STATE
                accountToAdd: targets.add,
                accountToEdit: targets.edit,
                defaultColDef,
                rowData,
                colDefs,
                pagination,
                // CONDITIONALS,
                isDebug,
                isLoading,
                isAddModalOpen,
                isEditModalOpen,
                isDeleteModalOpen,
                isEnterprise: isOnEnterprisePlan,
                isAddButtonDisabled,
                // EVENTS
                onGridReady,
                onColumnResized,
                clickAddButton,
                cancelAdd,
                cancelEdit,
                cancelDelete,
                submitAdd,
                submitEdit,
                submitDelete,
            };
        },
    });
</script>
