<template>
    <router-view v-slot="{ Component }">
        <!-- <keep-alive> -->
        <component
            v-if="isAdministrator"
            :is="Component"
        />
        <!-- </keep-alive> -->
    </router-view>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount, computed } from 'vue';

    // <!-- COMPOSABLES -->
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useExpiredSubscriptionGate } from '@/hooks/gates';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- UTILITIES -->
    import { Maybe } from 'true-myth/dist/maybe';

    // <!-- TYPES -->
    import { ECNBState } from '@/store/types/ECNBStore';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Admin',
        setup(props, context) {
            // ==== COMPOSABLES ====
            const router = useRouter();
            const store = useStore();
            const gate = useExpiredSubscriptionGate();

            // Expiration guards.
            const { isNARADisabled } = useNARAFeature();
            const {
                user,
                organization,
                fetchCurrentUser,
                fetchSelectedOrganization,
                isSuperUser,
                isCurrentSubscriptionExpired,
                isOrganizationAdministrator,
            } = gate;

            onBeforeMount(async () => {
                // Fetch the current user if missing.
                if (!user.value) {
                    await fetchCurrentUser();
                }

                // Fetch the selected organization if missing.
                if (!organization.value) {
                    await fetchSelectedOrganization();
                }

                // Check if the user subscription is expired.
                if (
                    !isSuperUser.value &&
                    isCurrentSubscriptionExpired.value &&
                    isNARADisabled.value &&
                    router.currentRoute.value.path !=
                        '/subscription-information'
                ) {
                    return await router.push('/expired-subscription');
                }

                // Check if the user is an administrator.
                if (!isSuperUser.value && !isOrganizationAdministrator.value) {
                    return await router.push('/login');
                }
            });

            return {
                isAdministrator: isOrganizationAdministrator,
            };
        },
    });
</script>
