<template>
    <BasicModal
        :show="isUpgradeModalOpen"
        @close="cancelUpgrade"
    >
        <UpgradeSubscriptionForm
            :source="subscriptionToChange"
            :isLoading="isLoading"
            :size="modalSize"
            :debug="debug"
            @cancel="cancelUpgrade"
            @submit="submitUpgrade"
        />
    </BasicModal>

    <BasicModal
        :show="isExtendModalOpen"
        @close="cancelExtend"
    >
        <ExtendSubscriptionForm
            :source="subscriptionToChange"
            :isLoading="isLoading"
            :size="modalSize"
            :debug="debug"
            @cancel="cancelExtend"
            @submit="submitExtend"
        />
    </BasicModal>

    <AdminContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro v-if="!isEditing">
                    <PageDescription>
                        View and edit subscription details and settings.
                    </PageDescription>
                    <ModalButton
                        theme="primary"
                        label="Edit Subscription"
                        @click.prevent="clickEditButton"
                        :disabled="isLoading"
                    />
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading">
                    <SubscriptionFields
                        :source="initialData"
                        :isEditing="isEditing"
                        :isLoading="isLoading"
                        @cancel="cancelEdit"
                        @submit="submitEdit"
                        @upgrade="clickUpgradeButton"
                        @extend="clickExtendButton"
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </AdminContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="alerts?.state?.alerts?.value"
            @dismiss:alert="alerts?.methods?.dismissAlert($event)"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';

    // <!-- ENUMS -->
    import { Size } from '@/enums';

    // <!-- COMPONENTS -->
    import AdminContainer from '~Admin/components/wrappers/AdminContainer.vue';
    import PageWrapper from '@components/PageWrapper.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import SubscriptionFields from '~SubscriptionManager/components/SubscriptionFields.vue';
    import UpgradeSubscriptionForm from '~SubscriptionManager/components/UpgradeSubscriptionForm.vue';
    import ExtendSubscriptionForm from '~SubscriptionManager/components/ExtendSubscriptionForm.vue';

    // <!-- COMPOSABLES -->
    import { useSubscriptionManager } from '~SubscriptionManager/hooks/useSubscriptionManager';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SubscriptionInformation',
        components: {
            LoadingWrapper,
            AdminContainer,
            PageWrapper,
            PageIntro,
            PageDescription,
            ModalButton,
            SubscriptionFields,
            UpgradeSubscriptionForm,
            ExtendSubscriptionForm,
            BasicModal,
            BaseAlert,
        },
        props: {},
        emits: [],
        setup(props, context) {
            // <!-- COMPOSABLE -->
            const { state, constants, methods, alerts } =
                useSubscriptionManager();

            // <!-- CONSTANTS -->
            const { IsDebug } = constants;

            // <!-- COMPUTED PROPERTIES -->
            const {
                initialData,
                currentSubscriptionData,
                isLoading,
                isEditing,
                isUpgradeModalOpen,
                isExtendModalOpen,
            } = state;

            // <!-- EVENTS -->
            const {
                clickEditButton,
                clickUpgradeButton,
                clickExtendButton,
                cancelEdit,
                cancelUpgrade,
                cancelExtend,
                submitEdit,
                submitUpgrade,
                submitExtend,
            } = methods;

            // <!-- LIFECYCLE -->
            onMounted(async () => {
                // Refresh the data for the table.
                await methods.refreshResource();
                console.dir(alerts);
            });

            // <!-- EXPOSE -->
            return {
                // SERVICES
                alerts,
                // CONSTANTS,
                debug: IsDebug,
                // STATE
                initialData,
                subscriptionToChange: currentSubscriptionData,
                // CONDITIONALS
                isEditing,
                isLoading,
                isUpgradeModalOpen,
                isExtendModalOpen,
                // EVENTS
                clickEditButton,
                clickUpgradeButton,
                clickExtendButton,
                cancelEdit,
                cancelUpgrade,
                cancelExtend,
                submitEdit,
                submitUpgrade,
                submitExtend,
                // ENUMS
                modalSize: Size.tiny,
            };
        },
    });
</script>
