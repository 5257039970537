<template>
    <div
        v-if="!!source"
        :class="[isBusy ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="formData"
            #default="context"
            :actions="false"
            :config="config"
        >
            <FormKit
                type="text"
                name="organizationName"
                label="Organization Name"
                validation="required"
                :disabled="!isEditing"
                :innerClass="'$reset w-full border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500'"
            />
            <FormSection
                :grid="[
                    'grid',
                    'grid-cols-1',
                    'gap-y-2',
                    'gap-x-0',
                    'sm:grid-cols-4',
                ]"
            >
                <template #default>
                    <div class="w-full col-span-1">
                        <FormKit
                            type="select"
                            label="Subscription Level"
                            name="planId"
                            @input="selectPlanOption"
                            :options="planOptions"
                            :value="`${formData.planId}`"
                            :disabled="isBusy"
                            :ignore="true"
                        />
                    </div>
                    <div class="w-full col-span-3 sm:pl-2">
                        <FormKit
                            type="date"
                            label="Expiration Date"
                            name="expirationDate"
                            validation="required"
                            :disabled="isBusy"
                            innerClass="max-w-full"
                        />
                    </div>
                    <div class="w-full col-span-2">
                        <FormKit
                            v-if="isEnterpriseSelected"
                            type="number"
                            label="Maximum Users"
                            name="maxUsers"
                            step="10"
                            :disabled="isBusy"
                            :preserve="true"
                        />
                        <FormKit
                            v-else
                            type="number"
                            label="Maximum Users"
                            name="maxUsers"
                            step="5"
                            readonly="true"
                            inputClass="bg-gray-50 border-none cursor-not-allowed"
                            :disabled="isBusy"
                            :preserve="true"
                        />
                    </div>
                    <div class="w-full col-span-2 sm:pl-2">
                        <FormKit
                            v-if="isEnterpriseSelected"
                            type="number"
                            label="Maximum Locations"
                            name="maxLocations"
                            step="10"
                            :disabled="isBusy"
                            :preserve="true"
                        />
                        <FormKit
                            v-else
                            type="number"
                            label="Maximum Locations"
                            name="maxLocations"
                            step="5"
                            readonly="true"
                            inputClass="bg-gray-50 border-none cursor-not-allowed"
                            :disabled="isBusy"
                            :preserve="true"
                        />
                    </div>
                </template>
            </FormSection>
            <!-- EMAILS -->
            <FormSection
                class="mt-4"
                :grid="[
                    'grid',
                    'grid-cols-1',
                    'gap-y-2',
                    'gap-x-0',
                    'sm:grid-cols-3',
                ]"
            >
                <template #default>
                    <FormKit
                        type="group"
                        name="email"
                    >
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Primary Admin Email"
                                name="contact"
                                validation="required:trim|email"
                            />
                            <div
                                class="text-red-500 mb-1 text-xs"
                                v-if="errors.email.value"
                            >
                                The email entered must be unique.
                            </div>
                        </div>
                        <div>
                            <FormKit
                                type="text"
                                label="Billing Email"
                                name="billing"
                                validation="required:trim|email"
                            />
                            <div
                                class="text-red-500 mb-1 text-xs"
                                v-if="errors.email.value"
                            >
                                The email entered must be unique.
                            </div>
                        </div>
                        <div class="sm:pl-2">
                            <FormKit
                                type="select"
                                label="Reminder Frequency"
                                name="reminderFrequency"
                                :options="frequencyOptions"
                            />
                        </div>
                    </FormKit>
                </template>
            </FormSection>
            <!-- ADDRESS / REGION -->
            <FormSection
                class="mt-4"
                :grid="[
                    'grid',
                    'grid-cols-1',
                    'gap-y-2',
                    'gap-x-0',
                    'sm:grid-cols-4',
                ]"
            >
                <template #default>
                    <FormKit
                        type="group"
                        name="address"
                    >
                        <div class="w-full col-span-2 sm:pr-2">
                            <FormKit
                                type="select"
                                label="Country"
                                name="country"
                                :options="countries"
                                placeholder="Select Country"
                                validation="required:trim"
                                :preserve="true"
                            />
                        </div>
                        <div
                            class="w-full sm:pr-2"
                            v-if="isRegionInputVisible && isStateInputVisible"
                        >
                            <FormKit
                                type="select"
                                label="State"
                                name="state"
                                :options="states"
                                placeholder="Select State"
                                validation="required:trim"
                            />
                        </div>
                        <div
                            class="w-full sm:pr-2"
                            v-if="
                                isRegionInputVisible && isProvinceInputVisible
                            "
                        >
                            <FormKit
                                type="select"
                                label="Province"
                                name="state"
                                :options="provinces"
                                placeholder="Select Province"
                                validation="required:trim"
                            />
                        </div>
                        <div
                            class="w-full sm:pr-2"
                            :class="`${
                                (isRegionInputVisible &&
                                    isProvinceInputVisible === true) ||
                                (isRegionInputVisible &&
                                    isStateInputVisible === true)
                                    ? 'col-span-1'
                                    : 'col-span-2'
                            }`"
                        >
                            <FormKit
                                type="text"
                                label="City"
                                name="city"
                                validation="required:trim"
                                :disabled="isCityInputDisabled"
                            />
                        </div>
                    </FormKit>
                </template>
            </FormSection>
            <!-- TIMEZONE -->
            <FormSection
                class="mt-4"
                :grid="['grid', 'grid-cols-1', 'gap-x-0', 'sm:grid-cols-2']"
            >
                <template #default>
                    <div class="w-full pr-2">
                        <FormKit
                            type="select"
                            label="Time Zone"
                            name="timezone"
                            :options="timezoneOptions"
                            outerClass="col-span-1"
                            :validation-rules="{ isValidTimezone }"
                            :validation="[
                                ['required'],
                                ['*+not', 'placeholder'],
                                ['isValidTimezone'],
                            ]"
                            :validation-messages="{
                                not: 'Please enter a valid time zone.',
                            }"
                            :disabled="!isEditing"
                        />
                    </div>
                    <FormKit
                        type="select"
                        label="Temperature Scale"
                        name="temperatureScale"
                        outerClass="col-span-1 sm:min-w-max"
                        :validation="[['required'], ['*+not', 'placeholder']]"
                        :validation-messages="{
                            not: 'Please enter a valid temperature scale.',
                        }"
                        :disabled="!isEditing"
                        :options="temperatureOptions"
                    />
                </template>
            </FormSection>
            <!-- TREE LABELS -->
            <div class="mt-4 text-base font-bold">Manage Hierarchy</div>
            <div>
                <p
                    v-if="isNARAEnabled"
                    class="text-sm text-gray-500"
                >
                    NARA uses consistent terminology and abbreviations for
                    choices in the four Location Hierarchy levels.
                    <span class="font-bold"
                        >Level 1- Building. Level 2-Administration</span
                    >
                    (AO-Archives Office. FRC-Records Center. PLA-Presidential
                    Library Archives. PLM-Presidential Library Museum).
                    <span class="font-bold">Level 3-Space Type</span>
                    (ST-Storage. WR-Workroom. EX-Exhibition. RR-Research Room).
                    <span class="font-bold">Level 4-Monitoring Site</span>
                    (space name/number or a custom identifier).
                </p>
                <div
                    v-else
                    class="text-sm text-gray-500"
                >
                    Environmental management teams should work collaboratively
                    to develop consistent terminology for each level of the
                    location hierarchy to create a structure that is intuitive
                    to both collections and facilities staff. For example,
                    Second Floor, 2nd Fl., Two, or 2 can all refer to the same
                    floor but will be recognized as different floor levels in
                    the hierarchy.
                    <span class="font-bold"
                        >Determine which terminology is preferred and use it
                        consistently.</span
                    >
                    <p class="pt-2">
                        On this page, labels can be assigned to each hierarchy
                        level, for example Site (level 1), Building (level 2),
                        Floor (level 3), and Room (level 4), to assist with
                        naming conventions and consistency. The labels assigned
                        here will appear on the Add Location page to guide
                        editing of existing locations and naming of new
                        locations in this account.
                    </p>
                </div>
            </div>
            <FormSection
                v-if="formData?.treeLabels?.length === 4"
                class="mt-4"
                :grid="[
                    'grid',
                    'grid-cols-1',
                    'gap-y-2',
                    'gap-x-0',
                    'sm:grid-cols-4',
                ]"
            >
                <template #default>
                    <FormKit
                        type="list"
                        name="treeLabels"
                    >
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Level 1"
                                placeholder="Site"
                                validation="required:trim"
                                :index="0"
                            />
                        </div>
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Level 2"
                                placeholder="Building"
                                validation="required:trim"
                                :index="1"
                            />
                        </div>
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Level 3"
                                placeholder="Floor"
                                validation="required:trim"
                                :index="2"
                            />
                        </div>
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Level 4"
                                placeholder="Room"
                                validation="required:trim"
                                :index="3"
                            />
                        </div>
                    </FormKit>
                </template>
            </FormSection>
            <LoadingWrapper
                v-if="isEditing"
                :isLoading="isBusy"
            >
                <div class="flex flex-row-reverse pt-5">
                    <ModalButton
                        v-if="isEditing"
                        theme="primary"
                        label="Save"
                        :disabled="isBusy"
                        @click="() => clickSubmitButton(context)"
                    />
                    <ModalButton
                        theme="white"
                        label="Cancel"
                        @click="() => clickCancelButton()"
                    />
                </div>
            </LoadingWrapper>
        </FormKit>
        <div
            v-if="!!debug"
            class="mt-6"
        >
            <DebugFrame
                id="generic"
                :startHidden="frame.startHidden"
                :debug="frame.isEnabled"
                :data="frame.data"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        reactive,
        ref,
        toRefs,
        onBeforeMount,
    } from 'vue';
    import { createEventHook } from '@vueuse/core';

    // <!-- ENUMS -->
    import { CountryName } from '@/enums';

    // <!-- COMPONENTS -->
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';

    // <!-- TYPES -->
    /** @typedef {import('@formkit/core').FormKitNode} FormKitNode */

    // <!-- UTILITIES -->
    import clone from 'just-clone';
    import { isValidTimezone } from '@/utils/timezone';

    // <!-- COMPOSABLES -->
    import { useArraySome, watchDebounced } from '@vueuse/shared';
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { usePlanSelector } from '@/hooks/options/usePlanSelector';
    import { useRegionSelectors } from '@/hooks/options/useRegionSelectors';
    import { useTimezoneOptions } from '~DataManager/hooks/useTimezoneOptions';
    import { useTemperatureOptions } from '~DataManager/hooks/useTemperatureOptions';
    import { useReminderOptions } from '@/features/data-manager/hooks/useReminderOptions';
    import { states, provinces } from '@/hooks/useStates';
    import { countries } from '@/hooks/useCountries';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- HELPERS -->
    /**
     * Define the component state.
     *
     * @param {Object} props
     * @param {Vue.Ref<globalThis.Organization.AdminFormData>} props.source
     */
    const defineState = ({ source }) => {
        /** @type {Vue.Ref<Partial<globalThis.Organization.AdminFormData>>} */
        const initialData = ref({
            ...source.value,
        });

        /** @type {Vue.Ref<Partial<globalThis.Organization.AdminFormData>>} */
        const formData = ref(
            clone({
                ...initialData.value,
            })
        );

        /** Errors object. */
        const errors = {
            /** Potential email errors. */
            email: ref(''),
        };

        /** @type {Vue.Ref<Partial<import('@formkit/core').FormKitProps>>} */
        const config = ref({
            delay: 250,
            validationVisibility: 'blur',
        });

        return {
            initialData,
            formData,
            errors,
            config,
        };
    };

    /**
     * Define the component computed properties.
     *
     * @param {ReturnType<defineState> & { isLoading: Vue.Ref<boolean>, planSelector: ReturnType<usePlanSelector> }} props
     */
    const defineComputed = ({ isLoading, formData, planSelector }) => {
        const isCityInputDisabled = computed(() => {
            const selectedValues = formData.value?.address;
            return (
                selectedValues?.country === '' ||
                selectedValues?.country === 'Select Country' ||
                selectedValues?.country === null
            );
        });

        const isStateInputVisible = computed(() => {
            const selectedValues = formData.value?.address;
            return selectedValues?.country === CountryName.ByISO3.USA;
        });

        const isProvinceInputVisible = computed(() => {
            const selectedValues = formData.value?.address;
            return selectedValues?.country === CountryName.ByISO3.CAN;
        });

        const isRegionInputVisible = computed(() => {
            return isStateInputVisible.value || isProvinceInputVisible.value;
        });

        const isEnterpriseSelected = computed(() => {
            const availableOptions = planSelector.state.options.value;
            const currentOption = availableOptions.find(
                (o) => o.value === `${formData.value?.planId}`
            );
            return currentOption?.hasLabel('Enterprise') === true;
        });

        const isBusy = useArraySome(
            [isLoading, planSelector.state.plans.isLoading],
            (condition) => condition === true
        );

        return {
            isCityInputDisabled,
            isStateInputVisible,
            isProvinceInputVisible,
            isRegionInputVisible,
            isEnterpriseSelected,
            isBusy,
        };
    };

    /**
     * Define the component event hooks.
     */
    const defineEvents = () => {
        /** @type {EventHook<{ data?: Partial<globalThis.Organization.AdminFormData> }>} */
        const submit = createEventHook();
        /** @type {EventHook<{ reason?: string }>} */
        const cancel = createEventHook();
        /** @type {EventHook<void>} */
        const reset = createEventHook();
        /** @type {EventHook<Partial<globalThis.Organization.AdminFormData>>} */
        // const input = createEventHook();
        /** @type {EventHook<Partial<globalThis.Organization.AdminFormData>>} */
        const selected = createEventHook();
        /** @type {EventHook<Partial<globalThis.Organization.AdminFormData>>} */
        const changed = createEventHook();
        return {
            submit: submit.trigger,
            cancel: cancel.trigger,
            reset: reset.trigger,
            // input: input.trigger,
            selected: selected.trigger,
            changed: changed.trigger,
            onSubmit: submit.on,
            onCancel: cancel.on,
            onReset: reset.on,
            // onInput: input.on,
            onSelected: selected.on,
            onChanged: changed.on,
        };
    };

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'OrganizationFields',
        components: {
            FormSection,
            ModalButton,
            DebugFrame,
            LoadingWrapper,
        },
        props: {
            source: {
                /** @type {Vue.PropType<globalThis.Organization.AdminFormData>} */
                type: Object,
            },
            isEditing: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['submit', 'cancel'],
        setup(props, { emit }) {
            // Destructure the required props.
            const { source, isLoading } = toRefs(props);

            // <!-- COMPOSABLES -->
            const planSelector = usePlanSelector();
            const regionSelectors = useRegionSelectors();
            const frequencyOptions = useReminderOptions();
            const timezoneOptions = useTimezoneOptions();
            const temperatureOptions = useTemperatureOptions();

            // <!-- STATE -->
            const { initialData, formData, config, errors } = defineState({
                source,
            });

            // <!-- COMPUTED PROPERTIES -->
            const {
                isCityInputDisabled,
                isStateInputVisible,
                isProvinceInputVisible,
                isRegionInputVisible,
                isEnterpriseSelected,
                isBusy,
            } = defineComputed({
                initialData,
                formData,
                config,
                errors,
                planSelector,
                isLoading,
            });

            const { isNARAEnabled } = useNARAFeature();

            // <!-- EVENTS -->
            const {
                submit,
                cancel,
                reset,
                // input,
                selected,
                changed,
                onSubmit,
                onCancel,
                onReset,
                // onInput,
                onSelected,
                onChanged,
            } = defineEvents();

            // <!-- EVENT TRIGGERS -->

            /**
             * Click the save button.
             * @param {import('@formkit/core').FormKitFrameworkContext} context
             */
            const clickSubmitButton = ({ state }) => {
                config.value.validationVisibility = 'live';
                if (state.valid) {
                    submit({ data: formData.value });
                    console.log('Form submitted.');
                } else {
                    console.error('Form is not valid.');
                }
            };

            /**
             * Click the cancel button.
             */
            const clickCancelButton = () => {
                cancel({ reason: 'User canceled.' });
            };

            /**
             * Select the specific plan option.
             *
             * @param {string} value
             * @param {import('@formkit/core').FormKitNode} node
             */
            const selectPlanOption = (value, node) => {
                console.dir({ [`${node?.name ?? '?'}`]: value });
                selected({ planId: Number.parseInt(value) });
            };

            // <!-- EVENT LISTENERS -->

            // Callback invoked when `submit` event is raised.
            onSubmit(({ data }) => {
                const target = clone({ ...data });
                emit('submit', { target });
            });

            // Callback invoked when `cancel` event is raised.
            onCancel(({ reason }) => {
                reset(); // Reset the form data on cancellation.
                emit('cancel', { reason });
            });

            // Callback invoked when `reset` event is raised.
            onReset(() => {
                // Initialize the form data back to its original source.
                formData.value = clone({ ...initialData.value });
                // Update the inputs that are dependent on the selected plan.
                selected(formData.value);
            });

            // Callback invoked when `selected` event is raised.
            onSelected(({ planId }) => {
                // Assign the selected plan to the appropriate form data field.
                formData.value.planId = planId;
            });

            // Callback invoked when `changed` event is raised.
            onChanged(({ planId }) => {
                // Get references to the clean and dirty form data.
                const original = initialData.value;

                // Compute if the original expiration date should be used.
                const useOriginalExpirationDate =
                    original?.expirationDate !== undefined &&
                    original?.planId === planId;
                const useOriginalUserLimit =
                    Number.isFinite(original?.maxUsers) &&
                    original?.planId === planId;
                const useOriginalLocationLimit =
                    Number.isFinite(original?.maxUsers) &&
                    original?.planId === planId;

                // Get the information fields.
                const {
                    id,
                    name,
                    maxUsers,
                    maxLocations,
                    pricePerYear,
                    duration,
                } = planSelector.getPlanInformation(planId);

                // Get the expiration date using the duration or the original date, conditionally.
                const expirationDate = useOriginalExpirationDate
                    ? original?.expirationDate
                    : planSelector
                          .calculateExpirationDateFromSubscriptionLength(
                              duration
                          )
                          .toISOString()
                          .substring(0, 10);

                const userLimit = useOriginalUserLimit
                    ? original?.maxUsers
                    : maxUsers ?? Infinity;

                const locationLimit = useOriginalLocationLimit
                    ? original?.maxLocations
                    : maxLocations ?? Infinity;

                // Update detail fields.
                formData.value.maxUsers = userLimit;
                formData.value.maxLocations = locationLimit;
                formData.value.pricePerYear = pricePerYear;
                (formData.value.expireAt = new Date(expirationDate)),
                    (formData.value.planName = name);
                formData.value.expirationDate = expirationDate;
                formData.value.planId = id;
            });

            // <!-- COMPONENT LIFECYCLE -->

            onBeforeMount(() => {
                reset();
            });

            // <!-- WATCHERS -->

            watchDebounced(
                [() => formData.value?.planId],
                ([value], [previous]) => {
                    // When dirty, update the expiration date.
                    if (
                        value == undefined ||
                        value == null ||
                        value !== previous
                    ) {
                        // Update the plan using the current value.
                        changed({ planId: value });
                    }
                },
                {
                    deep: true,
                    debounce: 50,
                    maxWait: 100,
                }
            );

            // ==== DEBUG ====
            /**
             * Computed debug frame.
             */
            const frame = computed(() => {
                // Prepare data.
                const data = [
                    DebugObject.create(`Dirty Form Data`, {
                        formData: formData.value,
                    }),
                    DebugObject.create(`Initial Form Data`, {
                        initialData: initialData.value,
                    }),
                    DebugObject.create(`Status`, {
                        isEditing: props.isEditing,
                        isLoading: props.isLoading,
                    }),
                    DebugObject.create(`Region Selector`, {
                        isCityInputDisabled: isCityInputDisabled.value,
                        isRegionInputVisible: isRegionInputVisible.value,
                        isStateInputVisible: isStateInputVisible.value,
                        isProvinceInputVisible: isProvinceInputVisible.value,
                    }),
                    DebugObject.create(`Region Selector`, {
                        isCityInputDisabled: isCityInputDisabled.value,
                        isRegionInputVisible: isRegionInputVisible.value,
                        isStateInputVisible: isStateInputVisible.value,
                        isProvinceInputVisible: isProvinceInputVisible.value,
                    }),
                ];
                // Return new frame instance.
                return useDebugFrame({
                    isEnabled: true,
                    startHidden: true,
                    data,
                });
            });

            // <!-- EXPOSE -->
            return {
                formData,
                config,
                errors,
                isNARAEnabled,
                isValidTimezone,
                frequencyOptions,
                timezoneOptions,
                temperatureOptions,
                planOptions: planSelector.state.options,
                countries: regionSelectors.country.options,
                states: regionSelectors.state.options,
                provinces: regionSelectors.province.options,
                isCityInputDisabled,
                isRegionInputVisible,
                isStateInputVisible,
                isProvinceInputVisible,
                isEnterpriseSelected,
                isBusy,
                frame,
                clickSubmitButton,
                clickCancelButton,
                selectPlanOption,
            };
        },
    });
</script>
