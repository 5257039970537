// <!-- API -->
import { ref, computed, watchEffect } from 'vue';
import {
    computedEager,
    createEventHook,
    promiseTimeout,
    useAsyncState,
} from '@vueuse/core';
import accounts from '@/api/v2/accounts';
import users from '@/api/v2/users';
import organizations from '@/api/v2/organizations';

// <!-- ENUMS -->
import { ReminderFrequency, UserAccountRole } from '@/enums';

// <!-- MODELS -->
import { Organization } from '@/models/v2/organizations';
import { Account } from '@/models/v2/accounts';
import { User } from '@/models/v2/users';

// <!-- COMPONENTS -->
import UserManagerTableIcons from '~UserManager/components/UserManagerTableIcons.vue';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import clone from 'just-clone';
import omit from 'just-omit';
import isNil from 'lodash-es/isNil';
import { DateTimeISO } from '@/utils/datetime';
import { formatISO, isValid } from 'date-fns';
import { Maybe } from 'true-myth/dist/maybe';
import { formatTimeZone } from '@/utils/formatters';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';

// <!-- SUBMODULES -->

/**
 * Defines the manager lifecycle events.
 *
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class Events {
    /**
     * Create the submodule.
     */
    constructor() {
        this.defineEvents();
    }

    /**
     * Define the event hooks.
     */
    defineEvents() {
        // ==== STATUS ====
        {
            /**
             * Contains loading event hooks.
             */
            this.load = {
                /**
                 * Hook triggered when a loading operation begins. Contains a useful debugging string tag for the operation.
                 * @type {Vue.EventHook<string>}
                 */
                start: createEventHook(),
                /**
                 * Hook triggered when a loading operation ends. Contains a useful debugging string tag for the operation.
                 * @type {Vue.EventHook<string>}
                 */
                stop: createEventHook(),
            };
        }

        // ==== GRID ====
        {
            this.grid = {
                /**
                 * Hook triggered when an account item is dropped on the left panel.
                 * @type {Vue.EventHook<{ item: Pick<globalThis.Account.Model, 'id' | 'name'> }>}
                 */
                dragLeft: createEventHook(),
                /**
                 * Hook triggered when an account item is dropped on the right panel.
                 * @type {Vue.EventHook<{ item: Pick<globalThis.Account.Model, 'id' | 'name'> }>}
                 */
                dragRight: createEventHook(),
            };
        }

        // ==== ALERTS ====
        {
            /**
             * Contains notification alert event hooks.
             */
            this.alert = {
                /**
                 * Hook triggered in order to clear the specified alerts.
                 * @type {Vue.EventHook<(keyof Constants['AlertIDs'])[]>}
                 */
                clear: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                success: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                warning: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                error: createEventHook(),
            };
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Contains modal dialog event hooks.
             */
            this.modal = {
                /**
                 * Hook triggered when any modal is opened.
                 * @type {Vue.EventHook<{ id: keyof Constants['ModalIDs'] }>}
                 */
                open: createEventHook(),
                /**
                 * Hook triggered when any modal is closed.
                 * @type {Vue.EventHook<{ id: keyof Constants['ModalIDs'] }>}
                 */
                close: createEventHook(),
            };
        }

        // ==== FORM ACTIONS ====
        {
            /**
             * Contains specific form action event hooks.
             */
            this.form = {
                /**
                 * Contains add resource modal dialog event hooks.
                 */
                add: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: Omit<globalThis.User.Target, 'id'> }>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains edit resource modal dialog event hooks.
                 */
                edit: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: globalThis.User.Target }>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains confirm delete resource modal dialog event hooks.
                 */
                delete: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<void>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains confirm reset resource modal dialog event hooks.
                 */
                reset: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number, email: string }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<void>}
                     */
                    submit: createEventHook(),
                },
            };
        }

        // ==== MANAGER ACTIONS ====
        {
            /**
             * Hook triggered when the page must redirect to a different location.
             * @type {Vue.EventHook<{ to: Router.RouteLocationRaw }>}
             */
            this.redirect = createEventHook();
            // /**
            //  * Hook triggered when the page refreshes the user index.
            //  * @type {Vue.EventHook<{ index: globalThis.User.Model[] }>}
            //  */
            // this.refreshedUsers = createEventHook();
            // /**
            //  * Hook triggered when the page refreshes the account index.
            //  * @type {Vue.EventHook<{ index: Pick<globalThis.Account.Model, 'id' | 'name'>[] }>}
            //  */
            // this.refreshedAccounts = createEventHook();
            // /**
            //  * Hook triggered when the selected accounts change.
            //  * @type {Vue.EventHook<{ selected: Pick<globalThis.Account.Model, 'id' | 'name'>[] }>}
            //  */
            // this.selectedAccounts = createEventHook();
        }
    }
}

/**
 * Represents the constant, static state.
 *
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class Constants {
    /**
     * Create the submodule.
     */
    constructor() {
        this.defineConstants();
    }

    /**
     * Define the constants to be used.
     */
    defineConstants() {
        /**
         * Is debug mode?
         * @type {Readonly<boolean>}
         */
        this.IsDebug = process.env.NODE_ENV !== 'production';

        /** Status IDs. */
        this.StatusIDs = /** @type {const} */ ({
            success: 'success',
            failure: 'failure',
        });

        /** Alert IDs. */
        this.AlertIDs = /** @type {const} */ ({
            'add-error': 'add-error',
            'add-success': 'add-success',
            'add-warning': 'add-warning',
            'delete-error': 'delete-error',
            'delete-success': 'delete-success',
            'delete-warning': 'delete-warning',
            'edit-error': 'edit-error',
            'edit-success': 'edit-success',
            'edit-warning': 'edit-warning',
            'reset-error': 'reset-error',
            'reset-success': 'reset-success',
            'reset-warning': 'reset-warning',
            'refresh-users-error': 'refresh-users-error',
            'refresh-users-success': 'refresh-users-success',
            'refresh-users-warning': 'refresh-users-warning',
            'refresh-accounts-error': 'refresh-accounts-error',
            'refresh-accounts-success': 'refresh-accounts-success',
            'refresh-accounts-warning': 'refresh-accounts-warning',
        });

        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ({
            addUser: 'addUser',
            editUser: 'editUser',
            viewUser: 'viewUser',
            resetUser: 'resetUser',
            deleteUser: 'deleteUser',
        });

        /** @type {AgGrid.ColumnDef} */
        this.DefaultColumnDefinition = {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            lockPosition: true,
            minWidth: 150,
            flex: 1,
            cellClass: 'leading-5 py-4 break-normal',
            headerClass: 'whitespace-normal text-center',
            wrapHeaderText: true,
        };

        /** @type {globalThis.User.Row} */
        this.DefaultUserRow = {
            id: 1,
            email: 'example@example.com',
            username: 'test',
            firstName: 'Example',
            lastName: 'User',
            lastLoginAt: new Date(),
            createdAt: new Date(),
            accessType: 'guest',
        };

        /** @type {globalThis.User.Target} */
        this.DefaultUserTarget = {
            id: 1,
            email: '',
            username: '',
            firstName: '',
            lastName: '',
            userRole: 'data-manager',
            accounts: [],
            organization: null,
        };
    }
}

/**
 * Represents the reactive, computed, and async class state.
 *
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class State {
    /**
     * Create the submodule.
     * @param {Pick<UserManager, 'events' | 'store' | 'constants'>} context
     */
    constructor(context) {
        this.setContext(context);
        this.defineReactive();
        this.defineComputed();
        this.defineAsync();
    }

    /**
     * Assign the context.
     * @param {Pick<UserManager, 'events' | 'store' | 'constants'>} context
     */
    setContext(context) {
        this.context = context;
    }

    /**
     * Define the reactive properties.
     */
    defineReactive() {
        // ==== STATUS ====
        {
            /**
             * Indicates if the view is currently loading content.
             * @type {Vue.Ref<boolean>}
             */
            this.loading = ref(false);
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Specifies the currently open modal by id. Set to `null` when no modal is open.
             * @type {Vue.Ref<keyof Constants['ModalIDs']>}
             */
            this.modal = ref(null);
        }

        // ==== RESOURCE TARGETS ====
        {
            /**
             * Contains targets containing resource information for different actions.
             */
            this.targets = {
                /**
                 * Form data for the create resource form.
                 * @type {Vue.Ref<Omit<globalThis.User.Target, 'id'>>}
                 */
                add: ref(null),
                /**
                 * Form data for the update resource form.
                 * @type {Vue.Ref<globalThis.User.Target>}
                 */
                edit: ref(null),
                /**
                 * Form data for the delete resource form.
                 * @type {Vue.Ref<Pick<globalThis.User.Target, 'id' | 'organization'>>}
                 */
                delete: ref(null),
                /**
                 * Form data for the reset resource form.
                 * @type {Vue.Ref<Pick<globalThis.User.Target, 'id' | 'email' | 'organization'>>}
                 */
                reset: ref(null),
            };
        }

        // ==== GRID ====
        {
            const { constants } = this.context;

            /**
             * The display timezone used to format creation dates.
             * @type {Vue.Ref<TimeZone.Identifier>}
             */
            this.displayTimezone = ref('UTC');

            /**
             * Collection of selected accounts.
             * @type {Vue.Ref<Pick<globalThis.Account.Model, 'id' | 'name'>[]>}
             */
            this.selectedAccounts = ref([]);
            /**
             * Collection of column definitions used to configure the AgGrid table.
             * @type {Vue.Ref<AgGrid.ColumnDef[]>}
             */
            this.colDefs = ref([]);
            /**
             * Collection of row data models used to populate the left AgGrid table.
             * @type {Vue.Ref<Pick<globalThis.Account.Model, 'id' | 'name'>[]>}
             */
            this.leftRowData = ref([]);
            /**
             * Collection of row data models used to populate the right AgGrid table.
             * @type {Vue.Ref<Pick<globalThis.Account.Model, 'id' | 'name'>[]>}
             */
            this.rightRowData = ref([]);
            /**
             * Collection of column definitions used to configure the left AgGrid table box.
             * @type {Vue.Ref<AgGrid.ColumnDef[]>}
             */
            this.leftColDefs = ref([]);
            /**
             * Collection of column definitions used to configure the right AgGrid table box.
             * @type {Vue.Ref<AgGrid.ColumnDef[]>}
             */
            this.rightColDefs = ref([]);
            /**
             * Reference to the left grid api.
             * @type {Vue.Ref<AgGrid.GridApi>}
             */
            this.leftGridApi = ref(null);
            /**
             * Reference to the right grid api.
             * @type {Vue.Ref<AgGrid.GridApi>}
             */
            this.rightGridApi = ref(null);
        }
    }

    /**
     * Define the computed properties.
     */
    defineComputed() {
        // ==== STORE ====
        {
            // Get the store so we can access shared state.
            const { store } = this.context;

            /** The current authenticated user, if one is present. */
            this.currentUser = computedEager(() => store.state?.users?.me);

            /** The current selected account, if one is selected. */
            this.currentAccount = computedEager(
                () => store.state?.accounts?.account
            );

            /** The current selected organization, if one is selected. */
            this.currentOrganization = computedEager(
                () => store.state?.accounts?.organization
            );

            // Computed property definition.
            this.timeZoneAbbreviation = computed(() => {
                const timezone = this.displayTimezone.value;
                const formatted = formatTimeZone(timezone);
                return formatted;
            });
        }

        // ==== GRID ====
        {
            /**
             * Collection of row data models used to populate the AgGrid table.
             */
            this.rowData = computed(() => {
                const users = this.users.value;
                const organization = this.currentOrganization.value;
                const rows = users.map((user) => {
                    return User.createRowModel(user, organization);
                });
                return rows;
            });
        }

        // ==== STATUS ====
        {
            /**
             * Indicates if the view is currently loading content.
             */
            this.isLoading = computedEager(
                () =>
                    this.loading.value === true ||
                    this.areUsersLoading?.value === true ||
                    this.areAccountsLoading?.value === true
            );
            /**
             * Indicates if the view is not currently loading content.
             */
            this.isIdling = computedEager(() => !this.isLoading.value);
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isAddModalOpen = computedEager(
                () => this.modal.value === 'addUser'
            );
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isEditModalOpen = computedEager(
                () => this.modal.value === 'editUser'
            );
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isDeleteModalOpen = computedEager(
                () => this.modal.value === 'deleteUser'
            );
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isResetModalOpen = computedEager(
                () => this.modal.value === 'resetUser'
            );
        }
    }

    /**
     * Define the async properties.
     */
    defineAsync() {
        // Get access to events.
        const { events, constants } = this.context;

        // ==== ORGANIZATION USERS ====
        {
            /** @type {globalThis.User.Model[]} */
            const initialState = [];

            // Fetching the current organization's users.
            const fetchUsers = async () => {
                // Clear related alerts.
                events.alert.clear.trigger([
                    'refresh-users-success',
                    'refresh-users-warning',
                    'refresh-users-error',
                ]);
                const { fetchOrganizationUsers } = organizations;
                const organization = this.currentOrganization.value;
                const response = await fetchOrganizationUsers(organization);
                const result = response.isOk ? response.value : [];
                return result;
            };

            /** @type {import('@vueuse/core').UseAsyncStateOptions<boolean, globalThis.User.Model[]>} */
            const options = {
                immediate: true,
                throwError: false,
                resetOnExecute: true,
                onError: (e) => {
                    console.error(e);
                    events.alert.error.trigger({
                        id: 'refresh-users-error',
                        content: 'Failed to fetch organization users.',
                        dismissable: false,
                        ttl: 7000,
                    });
                },
                onSuccess: (data) => {
                    // Send a success alert.
                    if (constants.IsDebug) {
                        events.alert.success.trigger({
                            id: 'refresh-users-success',
                            content: `Fetched ${data.length} user(s) successfully.`,
                            dismissable: true,
                            ttl: 5000,
                        });
                    }
                },
            };

            /** The organization users. */
            const { state, isReady, isLoading, execute } = useAsyncState(
                fetchUsers,
                initialState,
                options
            );

            // Expose values from this state.
            this.users = state;
            this.areUsersReady = isReady;
            this.areUsersLoading = isLoading;
            this.refreshUsers = execute;
        }

        // ==== ORGANIZATION ACCOUNTS ====
        {
            /** @type {globalThis.Account.Model[]} */
            const initialState = [];

            // Fetching the current target's accounts.
            const fetchAccounts = async () => {
                // Clear related alerts.
                events.alert.clear.trigger([
                    'refresh-accounts-success',
                    'refresh-accounts-warning',
                    'refresh-accounts-error',
                ]);
                const { fetchOrganizationAccounts } = organizations;
                const organization = this.currentOrganization.value;
                const response = await fetchOrganizationAccounts(organization);
                const result = response.isOk ? response.value : [];
                return result;
            };

            /** @type {import('@vueuse/core').UseAsyncStateOptions<boolean, globalThis.Account.Model[]>} */
            const options = {
                immediate: false,
                throwError: false,
                resetOnExecute: true,
                onError: (e) => {
                    console.error(e);
                    events.alert.error.trigger({
                        id: 'refresh-accounts-error',
                        content: 'Failed to fetch organization accounts.',
                        dismissable: false,
                        ttl: 7000,
                    });
                },
                onSuccess: (data) => {
                    if (constants.IsDebug) {
                        // Send a success alert.
                        events.alert.success.trigger({
                            id: 'refresh-accounts-success',
                            content: `Fetched ${data.length} accounts(s) successfully.`,
                            dismissable: true,
                            ttl: 5000,
                        });
                    }
                },
            };

            /** The organization accounts. */
            const { state, isReady, isLoading, execute } = useAsyncState(
                fetchAccounts,
                initialState,
                options
            );

            // Expose values from this state.
            this.accounts = state;
            this.areAccountsReady = isReady;
            this.areAccountsLoading = isLoading;
            this.refreshAccounts = execute;
        }
    }
}

/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class Methods {
    /**
     * Create the submodule.
     * @param {Pick<UserManager, 'store' | 'router' | 'grid' | 'constants' | 'events' | 'state'>} context
     */
    constructor(context) {
        this.setContext(context);
        this.defineHooks();
        this.defineTriggers();
        this.defineActions();
        this.defineUtilities();
    }

    /**
     * Assign the context.
     * @param {Pick<UserManager, 'store' | 'router' | 'grid' | 'constants' | 'events' | 'state'>} context
     */
    setContext(context) {
        this.context = context;
    }

    /**
     * Define the event triggers.
     */
    defineHooks() {
        // ==== GRID ====
        {
            const { grid } = this.context;

            /** Register a callback invoked when a grid ready event is fired. */
            this.onGridReady = grid.onGridReady;
            /** Register a callback invoked when a column resize event is fired. */
            this.onColumnResized = grid.onColumnResized;
        }

        // ==== STATUS ====
        {
            const { load } = this.context.events;

            /** Register a callback invoked at the start of an async content loading operation. */
            this.onLoadStarted = load.start.on;
            /** Register a callback invoked at the end of an async content loading operation. */
            this.onLoadStopped = load.stop.on;
        }

        // ==== GRID ====
        {
            const { grid } = this.context.events;

            /** Register a callback invoked at end of drag operation. */
            this.onDragToLeftGrid = grid.dragLeft.on;
            /** Register a callback invoked at end of drag operation. */
            this.onDragToRightGrid = grid.dragRight.on;
        }

        // ==== ALERTS ====
        {
            const { alert } = this.context.events;

            /** Register a callback invoked after alert notifications are explicitly cleared. */
            this.onClearAlerts = alert.clear.on;
            /** Register a callback invoked after a successful alert notification is sent. */
            this.onAlertSuccess = alert.success.on;
            /** Register a callback invoked after a warning alert notification is sent. */
            this.onAlertWarning = alert.warning.on;
            /** Register a callback invoked after an error alert notification is sent. */
            this.onAlertError = alert.error.on;
        }

        // ==== MODAL DIALOG ====
        {
            const { modal } = this.context.events;

            /** Register a callback invoked when a modal is opened. */
            this.onModalOpened = modal.open.on;
            /** Register a callback invoked when a modal is closed. */
            this.onModalClosed = modal.close.on;
        }

        // ==== FORM ACTIONS ====
        {
            const { form } = this.context.events;

            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onAddButtonClicked = form.add.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onEditButtonClicked = form.edit.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onDeleteButtonClicked = form.delete.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onResetButtonClicked = form.reset.click.on;

            /** Register a callback invoked when the user cancels the specified form. */
            this.onAddCanceled = form.add.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onEditCanceled = form.edit.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onDeleteCanceled = form.delete.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onResetCanceled = form.reset.cancel.on;

            /** Register a callback invoked when the user submits the specified form. */
            this.onAddSubmitted = form.add.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onEditSubmitted = form.edit.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onDeleteSubmitted = form.delete.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onResetSubmitted = form.reset.submit.on;
        }

        // ==== MANAGER ACTIONS ====
        {
            const { events } = this.context;

            /** Register a callback invoked when the page must redirect to another location. */
            this.onRedirect = events.redirect.on;
            // /** Register a callback invoked when the users have been refreshed. */
            // this.onRefreshedUsers = events.refreshedUsers.on;
            // /** Register a callback invoked when the accounts have been refreshed. */
            // this.onRefreshedAccounts = events.refreshedAccounts.on;
            // /** Register a callback invoked when a set of accounts has been selected. */
            // this.onSelectedAccounts = events.selectedAccounts.on;
        }
    }

    /**
     * Define the event triggers.
     */
    defineTriggers() {
        // ==== STATUS ====
        {
            const { load } = this.context.events;

            /** Called at the start of an async content loading operation. */
            this.startLoading = load.start.trigger;
            /** Called at the completion of an async content loading operation. */
            this.stopLoading = load.stop.trigger;
        }

        // ==== GRID ====
        {
            const { grid } = this.context.events;

            /** Called at the end of a drag operation. */
            this.dragLeft = grid.dragLeft.trigger;
            /** Called at the end of a drag operation. */
            this.dragRight = grid.dragRight.trigger;
        }

        // ==== ALERTS ====
        {
            const { AlertIDs } = this.context.constants;
            const { alert } = this.context.events;

            /** Collection of all alert ids. Useful as a default. */
            const ids = /** @type {Array<keyof AlertIDs>} */ (
                Object.keys(AlertIDs)
            );

            /** Clear the specified alerts. If nothing is passed, all domain alerts are cleared. */
            this.clearAlerts = (targets = ids) => alert.clear.trigger(targets);
            /** Sends a successful alert notification. */
            this.sendSuccessAlert = alert.success.trigger;
            /** Sends a warning alert notification. */
            this.sendWarningAlert = alert.warning.trigger;
            /** Sends an error alert notification. */
            this.sendErrorAlert = alert.error.trigger;
        }

        // ==== MODAL DIALOG ====
        {
            const { modal } = this.context.events;

            /** Open the modal specified by id. */
            this.openModal = modal.open.trigger;
            /** Close the modal specified by id. */
            this.closeModal = modal.close.trigger;
        }

        // ==== FORM ACTIONS ====
        {
            const { form } = this.context.events;

            /** Handle a user click on the specified interface element. */
            this.clickAddButton = form.add.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickEditButton = form.edit.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickDeleteButton = form.delete.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickResetButton = form.reset.click.trigger;

            /** Triggered when the user cancels out of the specified form. */
            this.cancelAdd = form.add.cancel.trigger;
            /** Triggered when the user cancels out of the specified form. */
            this.cancelEdit = form.edit.cancel.trigger;
            /** Triggered when the user cancels out of the specified form. */
            this.cancelDelete = form.delete.cancel.trigger;
            /** Triggered when the user cancels out of the specified password reset. */
            this.cancelReset = form.reset.cancel.trigger;

            /** Triggered when the user submits the specified form. */
            this.submitAdd = form.add.submit.trigger;
            /** Triggered when the user submits the specified form. */
            this.submitEdit = form.edit.submit.trigger;
            /** Triggered when the user submits the specified form. */
            this.submitDelete = form.delete.submit.trigger;
            /** Triggered when the user resets the specified password. */
            this.submitReset = form.reset.submit.trigger;
        }

        // ==== MANAGER ACTIONS ====
        {
            const { state, events } = this.context;

            /** Send a redirect event with a `RouteLocationRaw` payload. */
            this.redirect = events.redirect.trigger;
            /** Refresh the organization users state. */
            this.refreshUsers = state.refreshUsers;
            /** Refresh the organization accounts state. */
            this.refreshAccounts = state.refreshAccounts;
        }
    }

    /**
     * Define action methods.
     */
    defineActions() {
        // ==== FORM ACTIONS ====
        {
            /**
             * Get the sanitized request.
             *
             * @param {Omit<globalThis.User.Target, 'id'>} target
             * @returns {globalThis.User.Request.CreateResource}
             */
            this.getSanitizedCreateRequest = (target) => {
                // Get the properties from the target.
                const body = { ...target };

                // Sanitize the organization id.
                const organization_id = body.organization?.id;

                // Sanitize the account ids.
                // Remove account field entirely, if this is an admin.
                const accounts = UserAccountRole.isValue('admin', body.userRole)
                    ? undefined
                    : body.accounts.map((account) => account?.id);

                // Create the payload.
                return {
                    user_name: body.username,
                    email: body.email,
                    first_name: body.firstName,
                    last_name: body.lastName,
                    role_name: body.userRole,
                    organization_id,
                    account_ids: accounts,
                };
            };

            /**
             * Get the sanitized request.
             *
             * @param {globalThis.User.Target} target
             * @returns {globalThis.User.Request.UpdateResource}
             */
            this.getSanitizedUpdateRequest = (target) => {
                // Get the properties from the target.
                const body = { ...target };

                // Sanitize the organization id.
                const organization_id = body.organization?.id;

                // Sanitize the account ids.
                // Remove account field entirely, if this is an admin.
                const accounts = UserAccountRole.isValue('admin', body.userRole)
                    ? undefined
                    : body.accounts.map((account) => account?.id);

                // Create the payload.
                return {
                    id: body.id,
                    user_name: body.username,
                    email: body.email,
                    first_name: body.firstName,
                    last_name: body.lastName,
                    role_name: body.userRole,
                    organization_id,
                    account_ids: accounts,
                };
            };

            /**
             * Create a new resource on the server.
             * @param {Omit<globalThis.User.Target, 'id'>} target
             */
            this.createResource = async (target) => {
                console.log(`[create::resource] <new User()>`);
                // Get the sanitized request.
                const request = Object.freeze(
                    this.getSanitizedCreateRequest(target)
                );
                // Send the response.
                const response = await users.createUser(request);
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (user) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'add-success',
                            content: 'Created 1 new user successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the user table.
                        this.refreshUsers();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'add-error',
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Update an existing resource on the server.
             * @param {globalThis.User.Target} target
             */
            this.updateResource = async (target) => {
                console.log(`[update::resource] <${target.id}>`);
                // Get the sanitized request.
                const request = Object.freeze(
                    this.getSanitizedUpdateRequest(target)
                );
                // Send the response.
                const response = await users.updateUserById(request);
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (user) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'edit-success',
                            content: 'Updated 1 user successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the user table.
                        this.refreshUsers();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'add-error',
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Delete an existing resource from the server.
             * @param {Pick<globalThis.User.Target, 'id' | 'organization'>} target
             */
            this.deleteResource = async (target) => {
                console.log(`[delete::resource] <${target.id}>`);
                // Prepare the request.
                const request = {
                    id: target.id,
                    organization: target?.organization?.id,
                };
                // Send the response.
                const response = await users.deleteUserById(request);
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (user) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'delete-success',
                            content: 'Deleted 1 user successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the user table.
                        this.refreshUsers();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'add-error',
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Reset an existing resource password on the server.
             * @param {Pick<globalThis.User.Target, 'id' | 'email' | 'organization'>} target
             */
            this.resetResource = async (target) => {
                console.log(`[reset::resource] <${target.id}>`);
                // Send the response.
                const request = {
                    user: target?.id,
                    organization: target?.organization?.id,
                };
                const response = await users.resetPassword(request);
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (user) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'reset-success',
                            content: `Sent password reset email to ${target.email}.`,
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the user table.
                        this.refreshUsers();
                    },
                    Err: (e) => {
                        // Send success alert.
                        this.sendErrorAlert({
                            id: 'reset-error',
                            title: `Failed to reset the password.`,
                            content: e.message,
                            dismissable: true,
                            ttl: 7000,
                        });
                    },
                });
            };
        }
    }

    /**
     * Define utility functions.
     */
    defineUtilities() {
        // ==== ERROR HANDLING ====
        {
            const { AlertIDs } = this.context.constants;
            const ids = /** @type {Array<keyof AlertIDs>} */ (
                Object.keys(AlertIDs)
            );

            /**
             * Handle the passed client error.
             * @param {keyof Constants['AlertIDs']} id The alert tag.
             * @param {Client.Error<Client.ErrorType>} err
             */
            this.handleClientError = (id, err) => {
                console.warn(`[${id}] ${err?.type}.`);
                this.sendErrorAlert({
                    id,
                    title: err?.title ?? 'Error',
                    messages: err?.messages ?? [
                        'An unexpected error occurred.',
                    ],
                    dismissable: true,
                    ttl: 10000,
                });
            };

            /**
             * Expire alerts after a scheduled delay.
             *
             * @param {(keyof Constants['AlertIDs'])[]} [targets]
             * @param {number} [delayInMilliseconds]
             */
            this.queueClearAlerts = async (
                targets = ids,
                delayInMilliseconds = 5000
            ) => {
                await promiseTimeout(delayInMilliseconds);
                this.clearAlerts(targets);
            };
        }

        // ==== UTILITY FUNCTIONS ====
        {
            // Get state.
            const { state } = this.context;

            /**
             * Map access level into its corresponding display text.
             * @param {String} value
             */
            this.getUserRoleDisplayText = (value) => {
                const id = value.toLowerCase();
                switch (id) {
                    case 'data-manager':
                        return 'Data Manager';
                    case 'data-analyst':
                        return 'Data Analyst';
                    case 'admin':
                        return 'Admin';
                    case 'guest':
                        return '';
                    default:
                        return value;
                }
            };

            /**
             * Cast account resource into the row data format.
             * @type {(account: globalThis.Account.Model) => Pick<globalThis.Account.Model, 'id' | 'name'>}
             */
            this.createAccountRowForListBox = (account) => {
                return {
                    id: account?.id,
                    name: account?.name,
                };
            };

            /**
             * Compare two values.
             * @type {AgGrid.ColumnDef['comparator']}
             */
            this.compareByLowercase = (valueA, valueB, nodeA, nodeB) => {
                /** @type {string} */
                const valueALower = valueA.toLowerCase().trim();
                /** @type {string} */
                const valueBLower = valueB.toLowerCase().trim();
                // Return comparison value.
                return valueALower.localeCompare(valueBLower, 'en');
            };

            /**
             * Format the date value for the handled field.
             * @type {AgGrid.ValueFormatterFunc<string>}
             */
            this.formatDate = (params) => {
                // Get the value.
                const date = Maybe.of(params.value).map((dt) => new Date(dt));
                // Conditionally execute based on if row was found.
                const formatted = date.match({
                    Just: (dt) => {
                        return dt.toLocaleDateString('en-ca', {
                            timeZone: state.displayTimezone.value,
                        });
                    },
                    Nothing: () => 'No date provided.',
                });
                // Return the value.
                return formatted;
            };

            /**
             * Format the user role.
             * @type {AgGrid.ValueFormatterFunc<UserRole>}
             */
            this.formatUserRole = (params) => {
                const role = Maybe.of(params.value).unwrapOr('guest');
                const formatted = this.getUserRoleDisplayText(role);
                return formatted;
            };
        }

        // ==== GRID FUNCTIONS ====
        {
            // Get the required submodules.
            const { state } = this.context;

            /**
             * Get row data by its index in the array.
             * @param {number} index
             */
            this.findRowByIndex = (index) => {
                const position = Maybe.of(index).unwrapOr(-1);
                const row = state.rowData.value[position];
                return Maybe.of(row);
            };

            /**
             * Handle the click event from the edit button.
             *
             * @param {MouseEvent} event Click event.
             * @param {number} index Node index.
             */
            this.handleEditButton = (event, index) => {
                // Clear all existing alerts.
                this.clearAlerts();
                // Find the row by index.
                const row = this.findRowByIndex(index);
                // Conditionally execute based on if row was found.
                row.match({
                    Just: (target) =>
                        this.clickEditButton({ event, id: target.id }),
                    Nothing: () =>
                        this.handleClientError('edit-error', {
                            type: 'Error',
                            title: 'Cannot edit user.',
                            messages: [`No row exists at position ${index}.`],
                            timestamp: new Date(),
                        }),
                });
            };

            /**
             * Handle the click event from the delete button.
             *
             * @param {MouseEvent} event Click event.
             * @param {number} index Node index.
             */
            this.handleDeleteButton = (event, index) => {
                // Clear all existing alerts.
                this.clearAlerts();
                // Find the row by index.
                const row = this.findRowByIndex(index);
                // Conditionally execute based on if row was found.
                row.match({
                    Just: (target) =>
                        this.clickDeleteButton({ event, id: target.id }),
                    Nothing: () =>
                        this.handleClientError('delete-error', {
                            type: 'Error',
                            title: 'Cannot delete user.',
                            messages: [`No row exists at position ${index}.`],
                            timestamp: new Date(),
                        }),
                });
            };

            /**
             * Handle the click event from the reset button.
             *
             * @param {MouseEvent} event Click event.
             * @param {number} index Node index.
             */
            this.handleResetButton = (event, index) => {
                // Clear all existing alerts.
                this.clearAlerts();
                // Find the row by index.
                const row = this.findRowByIndex(index);
                // Conditionally execute based on if row was found.
                row.match({
                    Just: (target) =>
                        this.clickResetButton({
                            event,
                            id: target.id,
                            email: target.email,
                        }),
                    Nothing: () =>
                        this.handleClientError('reset-error', {
                            type: 'Error',
                            title: 'Cannot reset user password.',
                            messages: [`No row exists at position ${index}.`],
                            timestamp: new Date(),
                        }),
                });
            };

            /**
             * Get keyed column definitions.
             * @returns {Readonly<Record<String, AgGrid.ColumnDef>>}
             */
            this.getColumnSchema = () => {
                return {
                    /** @type {AgGrid.ColumnDef} Table icons with button actions. */
                    icons: {
                        headerName: '',
                        field: 'actions',
                        cellRenderer: UserManagerTableIcons,
                        lockPosition: true,
                        filter: false,
                        maxWidth: 120,
                        cellRendererParams: {
                            handleEdit: this.handleEditButton,
                            handleDelete: this.handleDeleteButton,
                            handleResetPassword: this.handleResetButton,
                        },
                    },
                    id: {
                        headerName: 'User ID',
                        field: 'id',
                        maxWidth: 200,
                    },
                    username: {
                        headerName: 'Username',
                        field: 'username',
                        comparator: this.compareByLowercase,
                        minWidth: 130,
                        autoHeight: true,
                    },
                    email: {
                        headerName: 'Email',
                        field: 'email',
                        minWidth: 450,
                    },
                    lastLoginDate: {
                        headerName: `Last Login (${state.timeZoneAbbreviation.value})`,
                        field: 'lastLoginAt',
                        valueFormatter: this.formatDate,
                        minWidth: 100,
                    },
                    createdDate: {
                        headerName: `Date Created (${state.timeZoneAbbreviation.value})`,
                        field: 'createdAt',
                        valueFormatter: this.formatDate,
                        minWidth: 100,
                        sort: 'desc',
                    },
                    accessType: {
                        headerName: 'Access Type',
                        field: 'accessType',
                        valueFormatter: this.formatUserRole,
                        minWidth: 100,
                    },
                };
            };

            /**
             * Get column definitions in ordered array.
             * @returns {AgGrid.ColumnDef[]}
             */
            this.getColumnDefs = () => {
                const schema = this.getColumnSchema();
                return [
                    schema.icons,
                    schema.username,
                    schema.email,
                    schema.createdDate,
                    // NOTE: Hidden from the AgGrid column per IPI#67
                    schema.lastLoginDate,
                    schema.accessType,
                ];
            };

            /**
             * Get column definitions in ordered array.
             * @returns {AgGrid.ColumnDef[]}
             */
            this.getLeftColumnDefs = () => {
                return [
                    {
                        headerName: 'Accounts',
                        field: 'name',
                        rowDrag: true,
                        cellClass: 'flex items-center',
                    },
                ];
            };

            /**
             * Get column definitions in ordered array.
             * @returns {AgGrid.ColumnDef[]}
             */
            this.getRightColumnDefs = () => {
                return [
                    {
                        headerName: 'Selected Accounts',
                        field: 'name',
                        rowDrag: true,
                        cellClass: 'flex items-center',
                    },
                ];
            };
        }
    }
}

// <!-- MANAGER MODULE -->

/**
 * Orchestrates the organization management features.
 */
class UserManager {
    /**
     * Instantiate a new manager.
     * @param {Parameters<UserManager['boot']>[0]} [props] Composable parameters.
     */
    constructor(props = {}) {
        // Bind or inject services required by any submodules.
        this.boot(props);
        // Define the submodule interface.
        this.defineInterface();
        // Register the event listeners.
        this.registerEventListeners();
        // Register any watchers.
        this.registerWatchers();
    }

    /**
     * Assign the context.
     * @param {Object} services Composable parameters.
     * @param {Vuex.Store<ECNBState>} [services.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {Router.Instance} [services.router] Optional router to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [services.alerts] Alerts composable.
     * @param {ReturnType<useAgGridVue>} [services.grid] AgGrid composable.
     */
    boot({ store, router, alerts, grid }) {
        /** @type {Vuex.Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {Router.Instance} */
        this.router = router ?? useRouter();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAgGridVue>} */
        this.grid = grid ?? useAgGridVue();
    }

    /**
     * Initializes the submodules needed to fulfill the manager's tasks.
     */
    defineInterface() {
        // Define submodules.
        this.constants = new Constants();
        this.events = new Events();
        this.state = new State(this);
        this.methods = new Methods(this);
    }

    /**
     * Register the internal event listener callbacks.
     */
    registerEventListeners() {
        // Provide access to state and methods.
        const { state, methods: _ } = this;

        // ==== STATUS ====
        {
            _.onLoadStarted((tag) => {
                console.time(tag);
                state.loading.value = true;
            });

            _.onLoadStopped((tag) => {
                state.loading.value = false;
                console.timeEnd(tag);
            });
        }

        // ==== ALERTS ====
        {
            const { clearAlert, createAlert, pushAlert } = this.alerts.methods;

            // Clear the specified alerts.
            _.onClearAlerts((ids) => {
                ids.forEach((id) => clearAlert(id));
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertSuccess((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'success',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertWarning((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'warning',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertError((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'error',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });
        }

        // ==== MODAL DIALOG ====
        {
            // Sets the active modal.
            _.onModalOpened(({ id }) => {
                state.modal.value = id;
            });

            // Closes the active modal if it matches the given id.
            _.onModalClosed(({ id }) => {
                if (state.modal.value === id) {
                    state.modal.value = null;
                }
            });
        }

        // ==== FORM ACTIONS ====
        {
            const { state } = this;

            // Sets the appropriate target and opens the appropriate dialog.
            _.onAddButtonClicked(async ({ event }) => {
                console.log(`[click::add]`, event);
                await state.refreshAccounts();

                // Set the target and open the modal.
                state.targets.add.value = {
                    ...this.constants.DefaultUserTarget,
                    // @ts-ignore
                    id: undefined,
                    accounts: [],
                    organization: state.currentOrganization.value,
                };
                _.openModal({ id: 'addUser' });
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onEditButtonClicked(async ({ event, id }) => {
                console.log(`[click::edit] <${id}>`, event);
                await state.refreshAccounts();

                // Get the target user.
                const user = state.rowData.value.find((user) => user.id === id);

                // Create target from the user.
                /** @type {globalThis.User.Target} */
                const target = {
                    id: user.id,
                    username: user.username,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    lastLoginAt: user.lastLoginAt,
                    userRole: user.accessType,
                    accounts: user.accounts,
                    organization: state.currentOrganization.value,
                };

                // Set the target and open the modal.
                state.targets.edit.value = target;
                _.openModal({ id: 'editUser' });
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onDeleteButtonClicked(async ({ event, id }) => {
                console.log(`[click::delete] <${id}>`, event);
                const organization = state.currentOrganization.value;
                state.targets.delete.value = { id, organization };
                _.openModal({ id: 'deleteUser' });
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onResetButtonClicked(async ({ event, id, email }) => {
                console.log(`[click::reset] <${id}> {${email}}`, event);
                state.targets.reset.value = {
                    id,
                    email,
                    organization: state.currentOrganization.value,
                };
                _.openModal({ id: 'resetUser' });
            });

            // Closes the appropriate dialog and clears the target.
            _.onAddCanceled((event) => {
                console.log(
                    `[cancel::add]`,
                    event?.reason,
                    state.targets.add.value
                );
                _.closeModal({ id: 'addUser' });
                state.targets.add.value = null;
            });

            // Closes the appropriate dialog and clears the target.
            _.onEditCanceled((event) => {
                console.log(
                    `[cancel::edit]`,
                    event?.reason,
                    state.targets.edit.value
                );
                _.closeModal({ id: 'editUser' });
                state.targets.edit.value = null;
            });

            // Closes the appropriate dialog and clears the target.
            _.onDeleteCanceled((event) => {
                console.log(
                    `[cancel::delete]`,
                    event?.reason,
                    state.targets.delete.value
                );
                _.closeModal({ id: 'deleteUser' });
                state.targets.delete.value = null;
            });

            // Closes the appropriate dialog and clears the target.
            _.onResetCanceled((event) => {
                console.log(
                    `[cancel::reset]`,
                    event?.reason,
                    state.targets.reset.value
                );
                _.closeModal({ id: 'resetUser' });
                state.targets.reset.value = null;
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onAddSubmitted(async (event) => {
                console.log(`[submit:add] <${event?.target?.username}>`);
                try {
                    // Create new resource using the target data.
                    _.startLoading('submit:add');
                    await _.createResource(event?.target);
                    _.closeModal({ id: 'addUser' });
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:add');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onEditSubmitted(async (event) => {
                console.log(
                    `[submit:edit] <${event?.target?.id}>`,
                    event?.target
                );
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:edit');
                    _.closeModal({ id: 'editUser' });
                    await _.updateResource(event?.target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:edit');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onDeleteSubmitted(async () => {
                const target = state.targets.delete.value;
                console.log(`[submit:delete] <${target?.id}>`);
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:delete');
                    _.closeModal({ id: 'deleteUser' });
                    await _.deleteResource(target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:delete');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onResetSubmitted(async () => {
                const target = state.targets.reset.value;
                console.log(
                    `[submit:reset] <${target?.id}> {${target?.email}}`
                );
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:reset');
                    _.closeModal({ id: 'resetUser' });
                    await _.resetResource(target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:reset');
                }
            });
        }

        // ==== MANAGER ACTIONS ====
        {
            // Redirect the page using the specified location.
            _.onRedirect(({ to }) => {
                console.log(`[redirect]`, to);
                this.router.push(to);
            });

            // // Refresh the table data.
            // _.onRefreshedUsers(({ index }) => {
            //     // Update the table with the row data models.
            //     const rows = index.map(User.createRowModel);
            //     // Assign the user row models.
            //     state.rowData.value = rows;
            // });

            // // Refresh the table data.
            // _.onRefreshedAccounts(({ index }) => {
            //     debugger;
            // });

            // // Update the list box selections.
            // _.onSelectedAccounts(({ selected }) => {
            //     debugger;
            // });
        }
    }

    /**
     * Register the following watch effects.
     */
    registerWatchers() {
        // Provide access to state and methods.
        const { state } = this;

        // ==== TIMEZONE ====
        {
            watchEffect(() => {
                // Update the current display timezone based on the selected account.
                state.displayTimezone.value =
                    state.currentAccount.value?.timezone ?? 'UTC';
            });
        }
    }
}

// <!-- COMPOSABLE -->

/**
 * Composable feature for managing the admin view-model state.
 */
export const useUserManager = () => {
    const manager = new UserManager();
    return manager;
};

// <!-- DEFAULT -->
export default useUserManager;
