<template>
    <BasicModal
        :show="isAddModalOpen"
        @close="cancelAdd"
        hideCloseButton
    >
        <AddOrganizationForm
            :source="organizationToAdd"
            :isEditing="true"
            :isLoading="isLoading"
            :debug="isDebug"
            @cancel="cancelAdd"
            @submit="submitAdd"
        />
    </BasicModal>
    <BasicModal
        :show="isEditModalOpen"
        @close="cancelEdit"
        hideCloseButton
    >
        <EditOrganizationForm
            :source="organizationToEdit"
            :isEditing="true"
            :isLoading="isLoading"
            :debug="isDebug"
            @cancel="cancelEdit"
            @submit="submitEdit"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Organization"
        confirmationText="Are you sure you want to delete this organization? This action cannot be undone."
        :open="isDeleteModalOpen"
        @close="cancelDelete"
        @cancel="cancelDelete"
        @delete="submitDelete"
    />

    <AdminContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        Switch, edit, delete, and add organizations.
                    </PageDescription>
                    <div class="flex">
                        <ModalButton
                            theme="secondary"
                            label="Download Data"
                            :busy="isLoading"
                            @click.prevent="clickDownloadButton"
                        />
                        <ModalButton
                            theme="primary"
                            label="Add Organization"
                            :busy="isLoading"
                            @click.prevent="clickAddButton"
                        />
                    </div>
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading || isRefreshing">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="true"
                        :paginationPageSize="25"
                        :columnDefs="colDefs"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                        overlayNoRowsTemplate="No organizations to display."
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </AdminContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="alerts.state.alerts.value"
            @dismiss:alert="alerts.methods.dismissAlert($event)"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';
    import { useStore } from 'vuex';

    // <!-- TYPES -->
    /** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@components/LoadingWrapper.vue';
    import PageWrapper from '@/components/PageWrapper.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import AdminContainer from '~Admin/components/wrappers/AdminContainer.vue';
    import { AgGridVue } from 'ag-grid-vue3';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import AddOrganizationForm from '~OrganizationManager/components/AddOrganizationForm.vue';
    import EditOrganizationForm from '~OrganizationManager/components/EditOrganizationForm.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';

    // <!-- COMPOSABLES -->
    import { useOrganizationManager } from '~OrganizationManager/hooks/useOrganizationManager';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Organizations',
        components: {
            PageIntro,
            PageDescription,
            LoadingWrapper,
            PageWrapper,
            AdminContainer,
            AgGridVue,
            ModalButton,
            BasicModal,
            BaseAlert,
            AddOrganizationForm,
            EditOrganizationForm,
            ConfirmDeleteModal,
        },
        setup(props, context) {
            // <!-- DEFINE -->
            const isDebug = process.env.NODE_ENV !== 'production';

            /** @type {Store} */
            const store = useStore();

            // ==== COMPOSABLE ====
            const { constants, state, methods, alerts } =
                useOrganizationManager();

            // ==== CONSTANTS ====
            const defaultColDef = { ...constants.DefaultColumnDefinition };

            // ==== STATE ====
            const { targets, rowData, colDefs } = state;

            // ==== COMPUTED PROPERTIES ====
            const {
                isLoading,
                isRefreshing,
                isAddModalOpen,
                isEditModalOpen,
                isDeleteModalOpen,
                editFormData,
                addFormData,
            } = state;

            // ==== EVENTS ====
            const {
                onGridReady,
                onColumnResized,
                clickAddButton,
                clickDownloadButton,
                cancelAdd,
                cancelEdit,
                cancelDelete,
                submitAdd,
                submitEdit,
                submitDelete,
            } = methods;

            // ==== SETUP ====
            colDefs.value = methods.getColumnDefs();

            // ==== LIFECYCLE ====
            onMounted(async () => {
                // Refresh the data for the table.
                await methods.refreshIndex();
            });

            // console.dir(editFormData);

            // ==== EXPOSE ====
            return {
                // SERVICES
                alerts,
                // STATE
                organizationToAdd: addFormData,
                organizationToEdit: editFormData,
                defaultColDef,
                rowData,
                colDefs,
                // CONDITIONALS,
                isDebug,
                isLoading,
                isRefreshing,
                isAddModalOpen,
                isEditModalOpen,
                isDeleteModalOpen,
                // EVENTS
                onGridReady,
                onColumnResized,
                clickAddButton,
                clickDownloadButton,
                cancelAdd,
                cancelEdit,
                cancelDelete,
                submitAdd,
                submitEdit,
                submitDelete,
            };
        },
    });
</script>
