<template>
    <BasicModal
        :show="isAddNARAStandardModalOpen"
        @close="onCancel.addNARAStandard"
    >
        <AddNARAStandardForm
            :sourceStandard="addTarget"
            :isEditing="true"
            :isLoading="isLoading"
            :onCancel="onCancel.addNARAStandard"
            :onSave="onSubmit.addNARAStandard"
        />
    </BasicModal>
    <BasicModal
        :show="isEditNARAStandardModalOpen"
        @close="onCancel.editNARAStandard"
    >
        <EditNARAStandardForm
            :sourceStandard="editTarget"
            :isEditing="true"
            :isLoading="isLoading"
            :onCancel="onCancel.editNARAStandard"
            :onSave="onSubmit.editNARAStandard"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Standard"
        confirmationText="Are you sure you want to delete this standard? This action cannot be undone."
        :open="isConfirmDeleteModalOpen"
        @delete="onSubmit.deleteNARAStandard"
        @cancel="onCancel.deleteNARAStandard"
        @close="onCancel.deleteNARAStandard"
    />
    <AdminContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        View, add, edit, and remove standards.
                    </PageDescription>
                    <ModalButton
                        theme="primary"
                        label="Add Standard"
                        :busy="isLoading"
                        @click.prevent="onClick.addNARAStandard"
                    />
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="true"
                        :paginationPageSize="10"
                        :rowData="rowData"
                        :rowHeight="null"
                        :columnDefs="adminColDefs"
                        :defaultColDef="defaultColDef"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                        overlayNoRowsTemplate="No standards to display."
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </AdminContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="alerts.state.alerts.value"
            @dismiss:alert="alerts.methods.dismissAlert($event)"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';
    import LoadingWrapper from '@components/LoadingWrapper.vue';
    import PageWrapper from '@components/PageWrapper.vue';
    import PageIntro from '@components/PageIntro.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import PageDescription from '@components/PageDescription.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import AdminContainer from '~Admin/components/wrappers/AdminContainer.vue';
    import AddNARAStandardForm from '~NARAStandards/components/AddNARAStandardForm.vue';
    import EditNARAStandardForm from '~NARAStandards/components/EditNARAStandardForm.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- COMPOSABLES -->
    import { useNARAStandardManager } from '~NARAStandards/hooks/useNARAStandardManager';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'NARA Standards',
        components: {
            AgGridVue,
            LoadingWrapper,
            PageWrapper,
            PageIntro,
            PageDescription,
            ModalButton,
            AdminContainer,
            BasicModal,
            BaseAlert,
            AddNARAStandardForm,
            EditNARAStandardForm,
            ConfirmDeleteModal,
        },
        setup(props, context) {
            // ==== COMPOSABLES ====
            const manager = useNARAStandardManager();
            const { alerts, grid, data, actions } = manager;

            // ==== STATE ====
            const defaultColDef = actions.getDefaultColDef();
            const { standards, addTarget, editTarget, rowData, adminColDefs } =
                data;

            // ==== COMPUTED PROPERTIES ====
            const {
                isLoading,
                isLoadedWithFailure,
                isLoadedWithSuccess,
                isAddNARAStandardModalOpen,
                isEditNARAStandardModalOpen,
                isConfirmDeleteModalOpen,
            } = data;

            // ==== EVENTS ====
            const { onGridReady, onColumnResized } = grid;
            const { onClick, onCancel, onSubmit, refreshNARAStandards } =
                actions;

            // ==== LIFECYCLE ====
            onMounted(async () => {
                // Refresh the NARAStandards on initial page load.
                await refreshNARAStandards();
            });

            // ==== EXPOSE ====
            return {
                manager,
                alerts,
                // STATE
                addTarget,
                editTarget,
                standards,
                rowData,
                defaultColDef,
                adminColDefs,
                // CONDITIONALS,
                isLoading,
                isLoadedWithFailure,
                isLoadedWithSuccess,
                isAddNARAStandardModalOpen,
                isEditNARAStandardModalOpen,
                isConfirmDeleteModalOpen,
                // EVENTS
                onClick,
                onCancel,
                onSubmit,
                onGridReady,
                onColumnResized,
            };
        },
    });
</script>
