<template>
    <div class="flex main-section">
        <div class="flex-grow overflow-y-auto print:overflow-y-visible pb-6">
            <div class="border-b border-gray-200 outer-border">
                <div class="max-w-7xl mx-auto px-5 pt-6 sm:pt-0">
                    <SubNav :subTabs="currentTabs" />
                    <PageTitle></PageTitle>
                </div>
            </div>
            <slot name="mainContent">
                <!-- FALLBACK CONTENT -->
                Administrator Content
            </slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted, computed, toRefs } from 'vue';
    import { useRouter } from 'vue-router';

    // <!-- COMPONENTS -->
    import SubNav from '@/components/SubNav.vue';
    import PageTitle from '@/components/PageTitle.vue';

    // <!-- COMPOSABLES -->
    import { useTabDefs } from '@/hooks/useTabDefs';
    import { useAdminTabs } from '@/hooks/env/useSelectTabs';

    // <!-- TYPE DEFINITIONS -->
    /** @typedef {'default' | 'commercial' | 'nara'} EnvironmentVisibilityRule */
    /** @typedef {'super' | 'admin' | 'data-manager' | 'data-analyst' | 'guest'} RoleVisibilityRole */
    /** @typedef {Tab & { environment: EnvironmentVisibilityRule, visibleFor: string[] }} TabWithVisibility */

    // <!-- CONSTANTS -->
    /**
     * Tabs for the main container.
     * @type {{ [key: string]: TabWithVisibility }}
     */
    const tabDefinitions = {
        organizations: {
            name: 'Organizations',
            to: '/admin/organizations',
            current: false,
            position: 0,
            environment: 'commercial',
            visibleFor: ['super'],
        },
        'subscription-information': {
            name: 'Subscription Information',
            to: '/subscription-information',
            current: false,
            position: 1,
            environment: 'commercial',
            visibleFor: ['super', 'admin'],
        },
        accounts: {
            name: 'Accounts',
            to: '/admin/accounts',
            current: false,
            position: 2,
            environment: 'default',
            visibleFor: ['super', 'admin'],
        },
        users: {
            name: 'Users',
            to: '/admin/users',
            current: false,
            position: 3,
            environment: 'default',
            visibleFor: ['super', 'admin'],
        },
        'nara-standards': {
            name: 'NARA Standards',
            to: '/admin/nara-standards',
            current: false,
            position: 4,
            environment: 'nara',
            visibleFor: ['super', 'admin'],
        },
    };

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AdminContainer',
        components: {
            SubNav,
            PageTitle,
        },
        setup(props) {
            // Get the router.
            const router = useRouter();

            // Define helper methods.

            /** @type {(tabs: Tab[]) => { name: string }} */
            const getFallbackRoute = (tabs = []) => {
                // Get first available tab in list or fallback to "Home" route.
                return {
                    name: tabs?.[0]?.name ?? 'Home',
                };
            };

            // Define the admin dashboard tabs.
            const { tabs: definedTabs } = useAdminTabs(tabDefinitions);

            // Define the current route tabs.
            const { tabs: currentTabs, updateCurrentTabByRoute } = useTabDefs(
                { tabs: definedTabs },
                router
            );

            // Get list of watched tabs.
            const watchedTabs = Object.values(tabDefinitions).map(
                (tab) => tab.to
            );

            /** @type {(value: any) => boolean} */
            const isWatchedTab = (value) => watchedTabs.includes(value);

            /** @type {(value: any) => boolean} */
            const isCurrentTab = (value) =>
                currentTabs.value?.map((tab) => tab.to).includes(value);

            // Check on the current route.
            router.beforeEach((to) => {
                // If we are doing something,
                // Make sure we redirect when necessary.
                if (isWatchedTab(to.path) && !isCurrentTab(to.path)) {
                    return getFallbackRoute(currentTabs.value);
                }
            });

            // Redirect if not in current tabs.
            const { currentRoute } = router;

            if (
                isWatchedTab(currentRoute.value?.path) &&
                !isCurrentTab(currentRoute.value?.path)
            ) {
                router.push(getFallbackRoute(currentTabs.value));
            }

            // Regsiter lifecycle.
            onMounted(updateCurrentTabByRoute);

            // ==== EXPOSE ====
            return {
                currentTabs,
            };
        },
    });
</script>

<style scoped>
    .main-section {
        min-height: calc(100vh - 4rem);
    }

    .outer-border div {
        border: 0;
    }

    @media print {
        .main-section {
            height: auto;
        }
    }
</style>
