// <!-- ENUMS -->
import { ReminderFrequency } from '@/enums';

// <!-- UTILITIES -->
import { Option } from '@/utils/options';

/**
 * @typedef {Option<keyof ReminderFrequencyOptions | 'Select Reminder', ReminderFrequency | 'placeholder'>} FrequencyOption
 */

/**
 * Compose options for a select input dialog.
 * @returns {FrequencyOption[]}
 */
export const useReminderOptions = () => {
    /** @type {FrequencyOption} */
    const prompt = new Option({
        label: 'Select Reminder',
        value: 'placeholder',
        attrs: { disabled: true },
    });
    /** @type {FrequencyOption[]} */
    const options = Option.fromRecord(ReminderFrequency._dictionary);
    return [prompt, ...options];
};
