<template>
    <div
        v-if="!!source"
        :class="[isBusy ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="formData"
            :actions="false"
            #default="context"
            :config="config"
        >
            <div class="flex flex-col">
                <FormKit
                    v-if="isBusy"
                    type="text"
                    label="Subscription Level"
                    name="planName"
                    :value="
                        planOptions.find(
                            (item) => item.value === formData.planId
                        )?.label
                    "
                    :ignore="true"
                    :readonly="true"
                    :preserve="true"
                    :validation-messages="{
                        required: 'This field is required.',
                    }"
                />
                <FormKit
                    v-else
                    type="select"
                    label="Subscription Level"
                    name="planId"
                    @input="selectPlanOption"
                    :value="`${formData.planId}`"
                    :options="planOptions"
                    :ignore="true"
                    :preserve="true"
                    :validation="[['required'], ['*+not', ...invalidOptions]]"
                    :validation-visibility="'live'"
                    :validation-messages="{
                        required: 'This field is required.',
                        not: getInvalidOptionMessage,
                    }"
                />
                <FormKit
                    type="number"
                    label="Maximum # of Users"
                    name="maxUsers"
                    :readonly="true"
                    :preserve="true"
                    :validation="[
                        ['required'],
                        ['number'],
                        ['min', formData.numberOfUsers ?? 0],
                    ]"
                    :validation-messages="{
                        required: 'This field is required.',
                    }"
                    :inputClass="'$reset w-full h-10 border px-3 rounded-lg text-base placeholder-gray-200 bg-gray-50 border-none disabled:'"
                    outerClass="col-span-1"
                />
                <FormKit
                    type="number"
                    label="Maximum # of Locations"
                    name="maxLocations"
                    :readonly="true"
                    :preserve="true"
                    :validation="[
                        ['required'],
                        ['number'],
                        ['min', formData.numberOfLocations ?? 0],
                    ]"
                    :validation-messages="{
                        required: 'This field is required.',
                    }"
                    :inputClass="'$reset w-full h-10 border px-3 rounded-lg text-base placeholder-gray-200 bg-gray-50 border-none disabled:'"
                    outerClass="col-span-1"
                />
                <FormKit
                    type="select"
                    label="Extend your subscription by:"
                    name="subscriptionLength"
                    :options="durationOptions"
                    validation="required"
                    :validation-messages="{
                        required: 'This field is required.',
                    }"
                />
                <FormSubmitCancel
                    :allowSave="!isBusy && isSubmissionAllowed"
                    :onSave="() => clickSubmitButton(context)"
                    :onCancel="clickCancelButton"
                />
            </div>
        </FormKit>
        <div
            v-if="debug"
            class="mt-4 border-gray-50 bg-gray-50 p-4"
            :key="JSON.stringify(formData)"
        >
            <code>Form Data:</code>
            <pre>{{ formData }}</pre>
            <code>Invalid Plan Codes:</code>
            <pre>{{ invalidOptions }}</pre>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref, computed, toRefs, onBeforeMount } from 'vue';
    import {
        createEventHook,
        watchTriggerable,
        computedEager,
    } from '@vueuse/core';

    // <!-- COMPONENTS -->
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';

    // <!-- UTILITIES -->
    import clone from 'just-clone';
    import { DurationISO } from '@/utils/datetime';
    import { add, formatISODuration } from 'date-fns';

    // <!-- COMPOSABLES -->
    import { useSubscriptionLength } from '@/hooks/plans';
    import { usePlanSelector } from '@/hooks/options/usePlanSelector';
    import { useDurationSelector } from '@/hooks/options/useDurationSelector';

    // <!-- HELPERS -->
    /**
     * Define the component state.
     *
     * @param {Object} props
     * @param {Vue.Ref<globalThis.Organization.ChangeSubscriptionFormData>} props.source
     */
    const defineState = ({ source }) => {
        /** @type {Vue.Ref<Partial<globalThis.Organization.ChangeSubscriptionFormData>>} */
        const initialData = ref({
            ...source.value,
        });

        /** @type {Vue.Ref<Partial<globalThis.Organization.ChangeSubscriptionFormData>>} */
        const formData = ref(
            clone({
                ...initialData.value,
            })
        );

        /** Errors object. */
        const errors = {
            /** Potential email errors. */
            email: ref(''),
        };

        /** @type {Vue.Ref<Partial<import('@formkit/core').FormKitProps>>} */
        const config = ref({
            delay: 250,
            validationVisibility: 'live',
        });

        return {
            initialData,
            formData,
            errors,
            config,
        };
    };

    /**
     * Define the computed state.
     *
     * @param {Partial<ReturnType<defineState>> & { planSelector: ReturnType<usePlanSelector>, planDurations: ReturnType<useSubscriptionLength>, durationSelector: ReturnType<useDurationSelector> }} state
     */
    const defineComputed = ({
        initialData,
        formData,
        planSelector,
        planDurations,
        durationSelector,
    }) => {
        const freeOption = computed(() => {
            const availableOptions = planSelector.state.options.value;
            return availableOptions.find((option) => {
                const { label, value } = option;
                const planId = Number.parseInt(value);
                const hasFreeLabel = label === 'Free';
                return !Number.isNaN(planId) && hasFreeLabel;
            });
        });

        const enterpriseOption = computed(() => {
            const availableOptions = planSelector.state.options.value;
            return availableOptions.find((option) => {
                const { label, value } = option;
                const planId = Number.parseInt(value);
                const hasEnterpriseLabel = label === 'Enterprise';
                return !Number.isNaN(planId) && hasEnterpriseLabel;
            });
        });

        const invalidOptions = computed(() => {
            const defaultValue = 'NaN';
            const freeValue = `${freeOption.value?.value}`;
            const enterpriseValue = `${enterpriseOption.value?.value}`;
            return [defaultValue, freeValue, enterpriseValue];
        });

        const planOptions = computed(() => {
            const currentOption = initialData.value.planId;
            const availableOptions = planSelector.state.options.value;
            return availableOptions.map((option) => {
                const { value, label } = option;
                const planId = Number.parseInt(value);
                const disabled = planId < currentOption;
                return {
                    ...option,
                    attrs: { disabled },
                };
            });
        });

        const durationOptions = computed(() => {
            const availableOptions = durationSelector.state.options.value;
            const currentPlanName = formData.value?.planName ?? '';
            const minimum = DurationISO.magnitude(
                planDurations.getMinimumSubscriptionLengthByPlanName(
                    currentPlanName
                )
            );
            const maximum = DurationISO.magnitude(
                planDurations.getMaximumSubscriptionLengthByPlanName(
                    currentPlanName
                )
            );
            return availableOptions.filter((option) => {
                const { value } = option;
                const magnitude =
                    value === '' ? 0 : DurationISO.magnitude(value);
                return (
                    currentPlanName === '' ||
                    (magnitude >= minimum && magnitude <= maximum)
                );
            });
        });

        const isFreeSelected = computedEager(() => {
            const data = formData.value;
            const name = data.planName;
            return name === 'Free';
        });

        const isEnterpriseSelected = computedEager(() => {
            const data = formData.value;
            const name = data.planName;
            return name === 'Enterprise';
        });

        const isSubmissionAllowed = computedEager(() => {
            return (
                isEnterpriseSelected.value !== true &&
                isFreeSelected.value !== true
            );
        });

        return {
            planOptions,
            durationOptions,
            invalidOptions,
            freeOption,
            enterpriseOption,
            isEnterpriseSelected,
            isFreeSelected,
            isSubmissionAllowed,
        };
    };

    /**
     * Define the component event hooks.
     */
    const defineEvents = () => {
        /** @type {EventHook<{ data?: Partial<globalThis.Organization.ChangeSubscriptionFormData> }>} */
        const submit = createEventHook();
        /** @type {EventHook<{ reason?: string }>} */
        const cancel = createEventHook();
        /** @type {EventHook<void>} */
        const reset = createEventHook();
        return {
            submit: submit.trigger,
            cancel: cancel.trigger,
            reset: reset.trigger,
            onSubmit: submit.on,
            onCancel: cancel.on,
            onReset: reset.on,
        };
    };

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ChangeSubscriptionFields',
        components: {
            FormSubmitCancel,
        },
        props: {
            source: {
                /** @type {Vue.PropType<globalThis.Organization.ChangeSubscriptionFormData>} */
                type: Object,
            },
            isLoading: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['submit', 'cancel'],
        setup(props, { emit }) {
            // Destructure the required props.
            const { source, isLoading } = toRefs(props);

            // <!-- COMPOSABLES -->
            const planDurations = useSubscriptionLength();
            const planSelector = usePlanSelector();
            const durationSelector = useDurationSelector();

            // <!-- STATE -->
            const { initialData, formData, config, errors } = defineState({
                source,
            });

            // <!-- COMPUTED PROPERTIES -->
            const {
                planOptions,
                invalidOptions,
                durationOptions,
                freeOption,
                enterpriseOption,
                isEnterpriseSelected,
                isFreeSelected,
                isSubmissionAllowed,
            } = defineComputed({
                initialData,
                formData,
                planSelector,
                durationSelector,
                planDurations,
            });

            // <!-- EVENTS -->
            const { submit, cancel, reset, onSubmit, onCancel, onReset } =
                defineEvents();

            // <!-- LIFECYCLE -->

            /**
             * Click the save button.
             * @param {import('@formkit/core').FormKitFrameworkContext} context
             */
            const clickSubmitButton = ({ state }) => {
                config.value.validationVisibility = 'live';
                if (isSubmissionAllowed.value && state.valid) {
                    submit({ data: formData.value });
                    console.log('Form submitted.');
                } else {
                    console.error('Form is not valid.');
                }
            };

            /**
             * Click the cancel button.
             */
            const clickCancelButton = () => {
                cancel({ reason: 'User canceled.' });
            };

            /**
             * Select the specific plan option.
             *
             * @param {string} value
             * @param {import('@formkit/core').FormKitNode} node
             */
            const selectPlanOption = (value, node) => {
                console.dir({ [`${node?.name ?? '?'}`]: value });
                formData.value.planId =
                    value === '' ? null : Number.parseInt(value);
            };

            /** @type {(props: { name: string, args: string[], node: import("@formkit/core").FormKitNode }) => string} */
            const getInvalidOptionMessage = ({ name, args, node }) => {
                if (node.value === `${enterpriseOption.value?.value}`) {
                    return `Please contact IPI in order to upgrade or modify an Enterprise plan.`;
                }
                return `This plan cannot be upgraded or modified.`;
            };

            // <!-- EVENT LISTENERS -->

            // Callback invoked when `submit` event is raised.
            onSubmit(({ data }) => {
                const target = clone({ ...data });
                emit('submit', { target });
            });

            // Callback invoked when `cancel` event is raised.
            onCancel(({ reason }) => {
                reset(); // Reset the form data on cancellation.
                emit('cancel', { reason });
            });

            // Callback invoked when `reset` event is raised.
            onReset(() => {
                // Initialize the form data back to its original source.
                formData.value = clone({ ...initialData.value });
                notifyPlanSelected();
            });

            // <!-- WATCHERS -->

            // When the subscription length changes, calculate the new expiration date.
            const { trigger: notifySubscriptionLengthChanged } =
                watchTriggerable(
                    () => formData.value.subscriptionLength,
                    (current, previous) => {
                        // Check if duration is different.
                        if (!!previous && !!current && current !== previous) {
                            // Get or default the start date.
                            const start =
                                formData.value.activeAt ?? new Date(Date.now());

                            // Get or default the duration.
                            const duration = !!current
                                ? DurationISO.parse(current)
                                : {
                                      days: 0,
                                  };

                            // Calculate the new end date.
                            const end = add(start, duration);

                            // Apply changes.
                            formData.value.activeAt = start;
                            formData.value.expireAt = end;
                        }
                    }
                );

            // When the subscription plan changes, select the new plan information.
            const { trigger: notifyPlanSelected } = watchTriggerable(
                () => formData.value.planId,
                (current, previous) => {
                    // Check if duration is different.
                    if (previous === undefined || current !== previous) {
                        // Get the plan summary data.
                        const info = !!current
                            ? planSelector.getPlanInformation(current)
                            : {};
                        formData.value.planName = info.name ?? 'Free';
                        formData.value.maxUsers = info.maxUsers ?? 0;
                        formData.value.maxLocations = info.maxLocations ?? 0;

                        // Overwrite with current maxUsers if this is enterprise.
                        if (
                            isEnterpriseSelected.value &&
                            current === initialData.value.planId &&
                            Number.isFinite(initialData.value.maxUsers)
                        ) {
                            formData.value.maxUsers =
                                initialData.value.maxUsers;
                        }

                        // Overwrite with current maxLocations if this is enterprise.
                        if (
                            isEnterpriseSelected.value &&
                            current === initialData.value.planId &&
                            Number.isFinite(initialData.value.maxLocations)
                        ) {
                            formData.value.maxLocations =
                                initialData.value.maxLocations;
                        }

                        // Get the default subscription length.
                        const duration =
                            planDurations.getSubscriptionLengthByPlanName(
                                formData.value.planName
                            );
                        formData.value.subscriptionLength =
                            formatISODuration(duration);
                    }
                }
            );

            // <!-- COMPONENT LIFECYCLE -->

            onBeforeMount(async () => {
                await planSelector.fetchPlans();
                reset();
            });

            // <!-- EXPOSE -->
            return {
                config: {
                    validationVisibility: 'dirty',
                },
                formData,
                planOptions,
                invalidOptions,
                durationOptions,
                freeOption,
                enterpriseOption,
                isBusy: isLoading,
                isEnterpriseSelected,
                isFreeSelected,
                isSubmissionAllowed,
                selectPlanOption,
                clickSubmitButton,
                clickCancelButton,
                getInvalidOptionMessage,
            };
        },
    });
</script>
