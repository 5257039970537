<template>
    <div
        v-if="!!sourceStandard"
        :class="[isLoading ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="dirtyStandard"
            #default="context"
            :actions="false"
            :config="{ validationVisibility: 'blur' }"
        >
            <FormKit
                type="text"
                name="standard"
                label="* Standard"
                validation="required"
                :classes="{
                    outer: isLoading ? 'animate-pulse' : 'w-full',
                    wrapper: 'w-full',
                    inner: isEditing ? 'w-full' : 'w-full border-none',
                    input: isEditing ? ' w-full' : 'w-full',
                }"
                :disabled="!isEditing"
                @input="$emit('update:dirtyStandard', { dirtyStandard })"
            />
            <FormKit
                type="textarea"
                name="description"
                label="* Description"
                :classes="{
                    outer: isLoading ? 'animate-pulse' : '',
                    wrapper: '',
                    inner: isEditing ? 'w-full' : 'w-full border-none',
                    input: isEditing
                        ? '$reset h-60 w-full rounded-lg border-black hover:border-blue-600'
                        : '$reset h-60 w-full rounded-lg border-gray-300 border-dashed cursor-not-allowed hover:text-gray-600',
                }"
                validation="required"
                :disabled="!isEditing"
            />
            <FormSection
                class="pt-4"
                :grid="[
                    'grid',
                    'grid-cols-1',
                    'gap-x-0',
                    'sm:grid-cols-4',
                    'mb-6',
                ]"
            >
                <FormKit
                    type="number"
                    label="* Min Temp. &#176;F"
                    name="minTemperature"
                    :disabled="!isEditing"
                    validation="required"
                    outerClass="pr-4"
                />
                <FormKit
                    type="number"
                    label="* Max Temp. &#176;F"
                    name="maxTemperature"
                    :disabled="!isEditing"
                    validation="required"
                    outerClass="pr-4"
                />
                <FormKit
                    type="number"
                    label="* Min %RH"
                    name="minRelativeHumidity"
                    :disabled="!isEditing"
                    validation="required"
                    outerClass="pr-4"
                />
                <FormKit
                    type="number"
                    label="* Max %RH"
                    name="maxRelativeHumidity"
                    :disabled="!isEditing"
                    validation="required"
                />
            </FormSection>
            <p class="text-gray-400 text-sm">* indicates a required field</p>
            <FormSubmitCancel
                :allowSave="context.state.valid && !isLoading"
                :onSave="onClickSave"
                :onCancel="onClickCancel"
            />
        </FormKit>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref, toRefs } from 'vue';

    // <!-- COMPONENTS -->
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'NARAStandardFields',
        components: {
            FormSection,
            FormSubmitCancel,
        },
        props: {
            sourceStandard: {
                /** @type {import('vue').PropType<Standards.AddFormData | Standards.EditFormData>} */
                type: Object,
            },
            isEditing: {
                /** @type {import('vue').PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {import('vue').PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            onCancel: {
                /** @type {import('vue').PropType<() => void>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            onSave: {
                /** @type {import('vue').PropType<(standard: Standards.AddFormData | Standards.EditFormData) => Promise<void>>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
        },
        emits: ['update:dirtyStandard'],
        setup(props, context) {
            // ==== PROPS ====
            /** @type {{ sourceStandard: Vue.Ref<Standards.AddFormData | Standards.EditFormData> }} */
            const { sourceStandard } = toRefs(props);

            // ==== STATE ====
            /** @type {Vue.Ref<Standards.AddFormData | Standards.EditFormData>} */
            const dirtyStandard = ref(Object.assign({}, sourceStandard.value));

            // ==== EVENTS ====

            /** Save the dirty standard. */
            const onClickSave = async () => {
                await props.onSave(dirtyStandard.value);
            };

            /** Clear the dirty standard. */
            const onClickCancel = () => {
                props.onCancel();
                dirtyStandard.value = null;
            };

            // ==== EXPOSE ====
            return {
                dirtyStandard,
                onClickSave,
                onClickCancel,
            };
        },
    });
</script>
