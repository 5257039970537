// <!-- API -->
import { ref, watchEffect, computed } from 'vue';
import { computedEager, createEventHook, promiseTimeout } from '@vueuse/core';
import organizations from '@/api/v2/organizations';

// <!-- ENUMS -->
import {
    ReminderFrequency,
    TemperatureScale,
    CountryName,
    StateName,
} from '@/enums';

// <!-- MODELS -->
import { Organization } from '@/models/v2/organizations';

// <!-- COMPONENTS -->
import OrganizationManagerTableIcons from '~OrganizationManager/components/OrganizationManagerTableIcons.vue';

// <!-- UTILITIES -->
import { Maybe } from 'true-myth/dist/maybe';
import { add } from 'date-fns';
import { formatTimeZone } from '@/utils/formatters';
import pick from 'just-pick';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';

// <!-- SUBMODULES -->
/**
 * Defines the manager lifecycle events.
 *
 * @class
 * Submodule for the {@link OrganizationManager} composable.
 */
class Events {
    /**
     * Create the submodule.
     */
    constructor() {
        this.defineEvents();
    }

    /**
     * Define the event hooks.
     */
    defineEvents() {
        // ==== STATUS ====
        {
            /**
             * Contains loading event hooks.
             */
            this.load = {
                /**
                 * Hook triggered when a loading operation begins. Contains a useful debugging string tag for the operation.
                 * @type {Vue.EventHook<string>}
                 */
                start: createEventHook(),
                /**
                 * Hook triggered when a loading operation ends. Contains a useful debugging string tag for the operation.
                 * @type {Vue.EventHook<string>}
                 */
                stop: createEventHook(),
            };
        }

        // ==== ALERTS ====
        {
            /**
             * Contains notification alert event hooks.
             */
            this.alert = {
                /**
                 * Hook triggered in order to clear the specified alerts.
                 * @type {Vue.EventHook<(keyof Constants['AlertIDs'])[]>}
                 */
                clear: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                success: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                warning: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                error: createEventHook(),
            };
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Contains modal dialog event hooks.
             */
            this.modal = {
                /**
                 * Hook triggered when any modal is opened.
                 * @type {Vue.EventHook<{ id: keyof Constants['ModalIDs'] }>}
                 */
                open: createEventHook(),
                /**
                 * Hook triggered when any modal is closed.
                 * @type {Vue.EventHook<{ id: keyof Constants['ModalIDs'] }>}
                 */
                close: createEventHook(),
            };
        }

        // ==== FORM ACTIONS ====
        {
            /**
             * Contains specific form action event hooks.
             */
            this.form = {
                /**
                 * Contains add resource modal dialog event hooks.
                 */
                add: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: Omit<globalThis.Organization.AdminFormData, 'id'> }>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains edit resource modal dialog event hooks.
                 */
                edit: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: globalThis.Organization.AdminFormData }>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains confirm delete resource modal dialog event hooks.
                 */
                delete: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<void>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains download button hook.
                 */
                download: {
                    /**
                     * Hook triggered when the download button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent }>}
                     */
                    click: createEventHook(),
                },
            };
        }

        // ==== MANAGER ACTIONS ====
        {
            /**
             * Hook triggered when the download event is fired. Accepts collection of organization ids.
             * @type {Vue.EventHook<void>}
             */
            this.downloadData = createEventHook();
            /**
             * Hook triggered when the selected organization is updated. Accepts the new organization id.
             * @type {Vue.EventHook<{ id: number }>}
             */
            this.switchOrganization = createEventHook();
            /**
             * Hook triggered when the page must redirect to a different location.
             * @type {Vue.EventHook<{ to: Router.RouteLocationRaw }>}
             */
            this.redirect = createEventHook();
            /**
             * Hook triggered when the page refreshes the index.
             * @type {Vue.EventHook<{ index: globalThis.Organization.Model[] }>}
             */
            this.refreshed = createEventHook();
        }
    }
}

/**
 * Represents the constant, static state.
 *
 * @class
 * Submodule for the {@link OrganizationManager} composable.
 */
class Constants {
    /**
     * Create the submodule.
     */
    constructor() {
        this.defineConstants();
    }

    /**
     * Define the constants to be used.
     */
    defineConstants() {
        /**
         * Is debug mode?
         * @type {Readonly<boolean>}
         */
        this.IsDebug = process.env.NODE_ENV !== 'production';

        /** Status IDs. */
        this.StatusIDs = /** @type {const} */ ({
            success: 'success',
            failure: 'failure',
        });

        /** Alert IDs. */
        this.AlertIDs = /** @type {const} */ ({
            'add-error': 'add-error',
            'add-success': 'add-success',
            'add-warning': 'add-warning',
            'delete-error': 'delete-error',
            'delete-success': 'delete-success',
            'delete-warning': 'delete-warning',
            'edit-error': 'edit-error',
            'edit-success': 'edit-success',
            'edit-warning': 'edit-warning',
            'refresh-error': 'refresh-error',
            'refresh-success': 'refresh-success',
            'refresh-warning': 'refresh-warning',
            'switch-error': 'switch-error',
            'switch-success': 'switch-success',
            'switch-warning': 'switch-warning',
            'download-error': 'download-error',
            'download-success': 'download-success',
        });

        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ({
            addOrganization: 'addOrganization',
            editOrganization: 'editOrganization',
            viewOrganization: 'viewOrganization',
            deleteOrganization: 'deleteOrganization',
        });

        /** @type {AgGrid.ColumnDef} */
        this.DefaultColumnDefinition = {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            lockPosition: true,
            minWidth: 150,
            flex: 1,
            cellClass: 'leading-5 py-4 break-normal',
            headerClass: 'whitespace-normal text-center',
            wrapHeaderText: true,
        };

        /** @type {globalThis.Organization.Row} */
        this.DefaultOrganizationRow = {
            id: 1,
            name: 'Test Org 1',
            billingEmail: 'billing@example.com',
            contactEmail: 'contact@example.com',
            primaryEmail: 'contact@example.com',
            subscriptionLevel: 'Basic Plus',
            subscriptionPrice: 850.25,
            timezone: 'UTC',
            reminderFrequency: ReminderFrequency.Never,
            temperatureScale: TemperatureScale.Fahrenheit,
            numberOfAccounts: 25,
            numberOfUsers: 6,
            numberOfLocations: 10,
            numberOfSubscriptions: 10,
            creationDate: new Date('12/15/2023'),
            expirationDate: new Date('12/16/2023'),
            lastReminderDate: new Date('12/17/2023'),
        };

        /** @type {globalThis.Organization.Target} */
        this.DefaultOrganizationTarget = {
            id: null,
            name: 'New Organization',
            email: {
                billing: 'billing@example.com',
                contact: 'primary@example.com',
            },
            address: {
                city: 'Rochester',
                state: StateName.ByISO2['US-NY'],
                country: CountryName.ByISO3.USA,
            },
            treeLabels: ['Site', 'Building', 'Floor', 'Room'],
            timezone: 'America/New_York',
            temperatureScale: TemperatureScale.Fahrenheit,
            reminderFrequency: ReminderFrequency.Never,
            currentSubscription: {
                id: null,
                plan: { id: 5, name: 'Free' },
                pricePerYear: 0,
                paidAt: null,
                activeAt: new Date(Date.now()),
                expireAt: add(Date.now(), { months: 3 }),
                maxUsers: 1,
                maxLocations: 3,
            },
        };

        /** @type {globalThis.Organization.AdminFormData} */
        this.DefaultFormData = {
            form: 'add',
            // ORGANIZATION
            organizationId: null,
            organizationName: '',
            timezone: 'UTC',
            temperatureScale: TemperatureScale.Fahrenheit,
            treeLabels: ['Site', 'Building', 'Floor', 'Room'],
            // ORGANIZATION::REGION
            address: {
                country: CountryName.ByISO3['USA'],
                state: StateName.ByISO2['US-NY'],
                city: '',
            },
            // ORGANIZATION::EMAIL
            email: {
                contact: '',
                billing: '',
                reminderFrequency: ReminderFrequency.Never,
            },
            // SUBSCRIPTION
            subscriptionId: null,
            maxUsers: Infinity,
            maxLocations: Infinity,
            pricePerYear: 0,
            paidAt: null,
            activeAt: new Date(Date.now()),
            expireAt: null, // Provided by the current subscription details.
            // PLAN
            planId: null,
            planName: '',
            expirationDate: '', // Calculated from the typical plan duration.
        };
    }
}

/**
 * Represents the reactive, computed, and async class state.
 *
 * @class
 * Submodule for the {@link OrganizationManager} composable.
 */
class State {
    /**
     * Create the submodule.
     * @param {Pick<OrganizationManager, 'store' | 'constants'>} context
     */
    constructor(context) {
        this.setContext(context);
        this.defineReactive();
        this.defineComputed();
        this.defineAsync();
    }

    /**
     * Assign the context.
     * @param {Pick<OrganizationManager, 'store' | 'constants'>} context
     */
    setContext(context) {
        this.context = context;
    }

    /**
     * Define the reactive properties.
     */
    defineReactive() {
        // ==== STATUS ====
        {
            /**
             * Indicates if the view is currently loading content.
             * @type {Vue.Ref<boolean>}
             */
            this.loading = ref(false);

            /**
             * Indicates if the view index is currently refreshing.
             * @type {Vue.Ref<boolean>}
             */
            this.refreshing = ref(false);
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Specifies the currently open modal by id. Set to `null` when no modal is open.
             * @type {Vue.Ref<keyof Constants['ModalIDs']>}
             */
            this.modal = ref(null);
        }

        // ==== RESOURCE TARGETS ====
        {
            /**
             * Contains targets containing resource information for different actions.
             */
            this.targets = {
                /**
                 * Form data for the create resource form.
                 * @type {Vue.Ref<Omit<globalThis.Organization.Target, 'id'>>}
                 */
                add: ref(null),
                /**
                 * Form data for the update resource form.
                 * @type {Vue.Ref<globalThis.Organization.Target>}
                 */
                edit: ref(null),
                /**
                 * Form data for the delete resource form.
                 * @type {Vue.Ref<Pick<globalThis.Organization.Target, 'id'>>}
                 */
                delete: ref(null),
            };
        }

        // ==== GRID ====
        {
            const { constants } = this.context;

            /**
             * The display timezone used to format expiration dates.
             * @type {Vue.Ref<TimeZone.Identifier>}
             */
            this.displayTimezone = ref('UTC');
            /**
             * Collection of row data models used to populate the AgGrid table.
             * @type {Vue.Ref<globalThis.Organization.Row[]>}
             */
            this.rowData = ref([constants.DefaultOrganizationRow]);
            /**
             * Collection of column definitions used to configure the AgGrid table.
             * @type {Vue.Ref<AgGrid.ColumnDef[]>}
             */
            this.colDefs = ref([]);
        }
    }

    /**
     * Define the computed properties.
     */
    defineComputed() {
        // ==== STORE ====
        {
            // Get the store so we can access shared state.
            const { store } = this.context;

            /** The current authenticated user, if one is present. */
            this.currentUser = computedEager(() => store.state?.users?.me);

            /** The current selected organization, if one is selected. */
            this.currentOrganization = computedEager(
                () => store.state?.accounts?.organization
            );

            /** The current selected account, if one is selected. */
            this.currentAccount = computedEager(
                () => store.state?.accounts?.account
            );

            // Computed property definition.
            this.timeZoneAbbreviation = computed(() => {
                const timezone = this.displayTimezone.value;
                const formatted = formatTimeZone(timezone);
                return formatted;
            });
        }

        // ==== STATUS ====
        {
            /**
             * Indicates if the view is currently loading content.
             */
            this.isLoading = computedEager(() => this.loading.value === true);
            /**
             * Indicates if the view is not currently loading content.
             */
            this.isIdling = computedEager(() => this.loading.value !== true);
            /**
             * Indicates if the view is currently loading content.
             */
            this.isRefreshing = computedEager(
                () => this.refreshing.value === true
            );
        }

        // ==== FORM DATA ====
        {
            const { DefaultFormData } = this.context.constants;
            const { edit, add } = this.targets;

            this.editFormData = computedEager(() => {
                const target = edit.value;
                const formData = !target
                    ? DefaultFormData
                    : {
                          form: 'edit',
                          // ORGANIZATION
                          organizationId: target?.id,
                          organizationName:
                              target?.name ?? DefaultFormData.organizationName,
                          timezone:
                              target?.timezone ?? DefaultFormData.timezone,
                          temperatureScale:
                              target?.temperatureScale ??
                              DefaultFormData.temperatureScale,
                          treeLabels:
                              target?.treeLabels ?? DefaultFormData.treeLabels,
                          // ORGANIZATION::REGION
                          address: {
                              country:
                                  target?.address?.country ??
                                  DefaultFormData.address.country,
                              state:
                                  target?.address?.state ??
                                  DefaultFormData.address.state,
                              city:
                                  target?.address?.city ??
                                  DefaultFormData.address.city,
                          },
                          // ORGANIZATION::EMAIL
                          email: {
                              contact:
                                  target?.email?.contact ??
                                  DefaultFormData.email.contact,
                              billing:
                                  target?.email?.billing ??
                                  DefaultFormData.email.billing,
                              reminderFrequency:
                                  target?.reminderFrequency ??
                                  DefaultFormData.email.reminderFrequency,
                          },
                          // SUBSCRIPTION
                          subscriptionId: target?.currentSubscription?.id,
                          maxUsers:
                              target?.currentSubscription?.maxUsers ??
                              DefaultFormData.maxUsers, // TBD.
                          maxLocations:
                              target?.currentSubscription?.maxLocations ??
                              DefaultFormData.maxLocations, // TBD.
                          pricePerYear:
                              target?.currentSubscription?.pricePerYear ??
                              DefaultFormData.pricePerYear, // TBD.
                          paidAt:
                              target?.currentSubscription?.paidAt ??
                              DefaultFormData.paidAt, // TBD.
                          activeAt:
                              target?.currentSubscription?.activeAt ??
                              DefaultFormData.activeAt, // TBD.
                          expireAt:
                              target?.currentSubscription?.expireAt ??
                              DefaultFormData.expireAt, // TBD.
                          // PLAN
                          planId:
                              target?.currentSubscription?.plan?.id ??
                              DefaultFormData.planId, // TBD.
                          planName:
                              target?.currentSubscription?.plan?.name ??
                              DefaultFormData.planName, // TBD.
                          expirationDate:
                              target?.currentSubscription?.expireAt
                                  ?.toISOString()
                                  .substring(0, 10) ??
                              DefaultFormData.expirationDate, // TBD.
                      };
                return formData;
            });

            this.addFormData = computedEager(() => {
                const target = add.value;
                return !target
                    ? DefaultFormData
                    : {
                          form: 'add',
                          // ORGANIZATION
                          organizationId: null,
                          organizationName:
                              target?.name ?? DefaultFormData.organizationName,
                          timezone:
                              target?.timezone ?? DefaultFormData.timezone,
                          temperatureScale:
                              target?.temperatureScale ??
                              DefaultFormData.temperatureScale,
                          treeLabels:
                              target?.treeLabels ?? DefaultFormData.treeLabels,
                          // ORGANIZATION::REGION
                          address: {
                              country:
                                  target?.address?.country ??
                                  DefaultFormData.address.country,
                              state:
                                  target?.address?.state ??
                                  DefaultFormData.address.state,
                              city:
                                  target?.address?.city ??
                                  DefaultFormData.address.city,
                          },
                          // ORGANIZATION::EMAIL
                          email: {
                              contact:
                                  target?.email?.contact ??
                                  DefaultFormData.email.contact,
                              billing:
                                  target?.email?.billing ??
                                  DefaultFormData.email.billing,
                              reminderFrequency:
                                  target?.reminderFrequency ??
                                  DefaultFormData.email.reminderFrequency,
                          },
                          // SUBSCRIPTION
                          subscriptionId: null, // TBD.
                          maxUsers: Infinity ?? DefaultFormData.maxUsers, // TBD.
                          maxLocations:
                              Infinity ?? DefaultFormData.maxLocations, // TBD.
                          pricePerYear: 0 ?? DefaultFormData.pricePerYear, // TBD.
                          paidAt: null ?? DefaultFormData.paidAt, // TBD.
                          activeAt: null ?? DefaultFormData.activeAt, // TBD.
                          expireAt: null ?? DefaultFormData.expireAt, // TBD.
                          // PLAN
                          planId: null ?? DefaultFormData.planId, // TBD.
                          planName: '' ?? DefaultFormData.planName, // TBD.
                          expirationDate: '' ?? DefaultFormData.expirationDate, // TBD.
                      };
            });
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isAddModalOpen = computedEager(
                () => this.modal.value === 'addOrganization'
            );
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isEditModalOpen = computedEager(
                () => this.modal.value === 'editOrganization'
            );
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isDeleteModalOpen = computedEager(
                () => this.modal.value === 'deleteOrganization'
            );
        }
    }

    /**
     * Define the async properties.
     */
    defineAsync() {}
}

/**
 * @class
 * Submodule for the {@link OrganizationManager} composable.
 */
class Methods {
    /**
     * Create the submodule.
     * @param {Pick<OrganizationManager, 'store' | 'router' | 'grid' | 'constants' | 'events' | 'state'>} context
     */
    constructor(context) {
        this.setContext(context);
        this.defineHooks();
        this.defineTriggers();
        this.defineActions();
        this.defineUtilities();
    }

    /**
     * Assign the context.
     * @param {Pick<OrganizationManager, 'store' | 'router' | 'grid' | 'constants' | 'events' | 'state'>} context
     */
    setContext(context) {
        this.context = context;
    }

    /**
     * Define the event triggers.
     */
    defineHooks() {
        // ==== GRID ====
        {
            const { grid } = this.context;

            /** Register a callback invoked when a grid ready event is fired. */
            this.onGridReady = grid.onGridReady;
            /** Register a callback invoked when a column resize event is fired. */
            this.onColumnResized = grid.onColumnResized;
        }

        // ==== STATUS ====
        {
            const { load } = this.context.events;

            /** Register a callback invoked at the start of an async content loading operation. */
            this.onLoadStarted = load.start.on;
            /** Register a callback invoked at the end of an async content loading operation. */
            this.onLoadStopped = load.stop.on;
        }

        // ==== ALERTS ====
        {
            const { alert } = this.context.events;

            /** Register a callback invoked after alert notifications are explicitly cleared. */
            this.onClearAlerts = alert.clear.on;
            /** Register a callback invoked after a successful alert notification is sent. */
            this.onAlertSuccess = alert.success.on;
            /** Register a callback invoked after a warning alert notification is sent. */
            this.onAlertWarning = alert.warning.on;
            /** Register a callback invoked after an error alert notification is sent. */
            this.onAlertError = alert.error.on;
        }

        // ==== MODAL DIALOG ====
        {
            const { modal } = this.context.events;

            /** Register a callback invoked when a modal is opened. */
            this.onModalOpened = modal.open.on;
            /** Register a callback invoked when a modal is closed. */
            this.onModalClosed = modal.close.on;
        }

        // ==== FORM ACTIONS ====
        {
            const { form } = this.context.events;

            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onAddButtonClicked = form.add.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onEditButtonClicked = form.edit.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onDeleteButtonClicked = form.delete.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onDownloadButtonClicked = form.download.click.on;

            /** Register a callback invoked when the user cancels the specified form. */
            this.onAddCanceled = form.add.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onEditCanceled = form.edit.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onDeleteCanceled = form.delete.cancel.on;

            /** Register a callback invoked when the user submits the specified form. */
            this.onAddSubmitted = form.add.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onEditSubmitted = form.edit.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onDeleteSubmitted = form.delete.submit.on;
        }

        // ==== MANAGER ACTIONS ====
        {
            const { events } = this.context;

            /** Register a callback invoked when the download is invoked. */
            this.onDownloadData = events.downloadData.on;
            /** Register a callback invoked when the page must switch organizations. */
            this.onSwitchOrganization = events.switchOrganization.on;
            /** Register a callback invoked when the page must redirect to another location. */
            this.onRedirect = events.redirect.on;
            /** Register a callback invoked when the organizations have been refreshed. */
            this.onRefreshed = events.refreshed.on;
        }
    }

    /**
     * Define the event triggers.
     */
    defineTriggers() {
        // ==== STATUS ====
        {
            const { load } = this.context.events;

            /** Called at the start of an async content loading operation. */
            this.startLoading = load.start.trigger;
            /** Called at the completion of an async content loading operation. */
            this.stopLoading = load.stop.trigger;
        }

        // ==== ALERTS ====
        {
            const { AlertIDs } = this.context.constants;
            const { alert } = this.context.events;

            /** Collection of all alert ids. Useful as a default. */
            const ids = /** @type {Array<keyof AlertIDs>} */ (
                Object.keys(AlertIDs)
            );

            /** Clear the specified alerts. If nothing is passed, all domain alerts are cleared. */
            this.clearAlerts = (targets = ids) => alert.clear.trigger(targets);
            /** Sends a successful alert notification. */
            this.sendSuccessAlert = alert.success.trigger;
            /** Sends a warning alert notification. */
            this.sendWarningAlert = alert.warning.trigger;
            /** Sends an error alert notification. */
            this.sendErrorAlert = alert.error.trigger;
        }

        // ==== MODAL DIALOG ====
        {
            const { modal } = this.context.events;

            /** Open the modal specified by id. */
            this.openModal = modal.open.trigger;
            /** Close the modal specified by id. */
            this.closeModal = modal.close.trigger;
        }

        // ==== FORM ACTIONS ====
        {
            const { form } = this.context.events;

            /** Handle a user click on the specified interface element. */
            this.clickAddButton = form.add.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickEditButton = form.edit.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickDeleteButton = form.delete.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickDownloadButton = form.download.click.trigger;

            /** Triggered when the user cancels out of the specified form. */
            this.cancelAdd = form.add.cancel.trigger;
            /** Triggered when the user cancels out of the specified form. */
            this.cancelEdit = form.edit.cancel.trigger;
            /** Triggered when the user cancels out of the specified form. */
            this.cancelDelete = form.delete.cancel.trigger;

            /** Triggered when the user submits the specified form. */
            this.submitAdd = form.add.submit.trigger;
            /** Triggered when the user submits the specified form. */
            this.submitEdit = form.edit.submit.trigger;
            /** Triggered when the user submits the specified form. */
            this.submitDelete = form.delete.submit.trigger;
        }

        // ==== MANAGER ACTIONS ====
        {
            const { events } = this.context;

            /** Send a download request. */
            this.downloadData = events.downloadData.trigger;
            /** Send a switch organization request. */
            this.switchOrganization = events.switchOrganization.trigger;
            /** Send a redirect event with a `RouteLocationRaw` payload. */
            this.redirect = events.redirect.trigger;
            /** Send an updated payload once the organizations are refreshed. */
            this.refreshed = events.refreshed.trigger;
        }
    }

    /**
     * Define action methods.
     */
    defineActions() {
        // ==== FORM ACTIONS ====
        {
            /**
             * Get the sanitized request.
             *
             * @param {Omit<globalThis.Organization.AdminFormData, 'id'>} target
             * @returns {globalThis.Organization.Request.CreateResource}
             */
            this.getSanitizedCreateRequest = (target) => {
                // Get the properties from the target.
                const body = { ...target };

                // Sanitize the reminder field.
                if (
                    !ReminderFrequency.hasValue(body.email?.reminderFrequency)
                ) {
                    body.email.reminderFrequency = ReminderFrequency.Never;
                }

                // Sanitize the tree labels.
                if (body?.treeLabels?.length === 4) {
                    // Copy the value.
                    body.treeLabels = [...body.treeLabels];
                } else {
                    // Use server default.
                    body.treeLabels = null;
                }

                // Create the payload.
                return {
                    name: body.organizationName,
                    billing_email: body?.email?.billing,
                    contact_email: body?.email?.contact,
                    reminder_frequency: body?.email?.reminderFrequency,
                    city: body?.address?.city,
                    state: body?.address?.state,
                    country: body?.address?.country,
                    tree_labels: body?.treeLabels,
                    timezone: body?.timezone,
                    temperature_scale: body?.temperatureScale,
                    current_subscription: {
                        subscription_level_id: body?.planId,
                        paid_at:
                            body?.paidAt?.toISOString() ??
                            new Date(Date.now()).toISOString(), // Mark admin-set plan details as "paid".
                        active_at: body?.activeAt?.toISOString(),
                        expire_at: body?.expirationDate,
                        max_users: body?.maxUsers,
                        max_locations: body?.maxLocations,
                    },
                };
            };

            /**
             * Get the sanitized request.
             *
             * @param {globalThis.Organization.AdminFormData} target
             * @returns {globalThis.Organization.Request.UpdateResource}
             */
            this.getSanitizedUpdateRequest = (target) => {
                // Get the properties from the target.
                const body = { ...target };

                // Sanitize the reminder field.
                if (
                    !ReminderFrequency.hasValue(body.email?.reminderFrequency)
                ) {
                    body.email.reminderFrequency = ReminderFrequency.Never;
                }

                // Sanitize the tree labels.
                if (body?.treeLabels?.length === 4) {
                    // Copy the value.
                    body.treeLabels = [...body.treeLabels];
                } else {
                    // Use server default.
                    body.treeLabels = null;
                }

                // Create the payload.
                return {
                    id: body.organizationId,
                    name: body.organizationName,
                    billing_email: body?.email?.billing,
                    contact_email: body?.email?.contact,
                    reminder_frequency: body?.email?.reminderFrequency,
                    city: body?.address?.city,
                    state: body?.address?.state,
                    country: body?.address?.country,
                    tree_labels: body?.treeLabels,
                    timezone: body?.timezone,
                    temperature_scale: body?.temperatureScale,
                    current_subscription: {
                        subscription_level_id: body?.planId,
                        paid_at: body?.paidAt?.toISOString(),
                        active_at: body?.activeAt?.toISOString(),
                        expire_at: body?.expirationDate,
                        max_users: body?.maxUsers,
                        max_locations: body?.maxLocations,
                    },
                };
            };

            /**
             * Create a new organization resource on the server.
             * @param {Omit<globalThis.Organization.AdminFormData, 'id'>} target
             */
            this.createResource = async (target) => {
                console.log(`[create::resource] <new Organization.Model()>`);
                // Get the sanitized request.
                const request = Object.freeze(
                    this.getSanitizedCreateRequest(target)
                );
                // Send the response.
                const response = await organizations.createOrganization(
                    request
                );
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (organization) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'add-success',
                            content: 'Created 1 new organization successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the page.
                        this.refreshIndex();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'add-error',
                            // @ts-ignore
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Update an existing organization resource on the server.
             * @param {globalThis.Organization.AdminFormData} target
             */
            this.updateResource = async (target) => {
                console.log(`[update::resource] <${target.organizationId}>`);
                // Get the sanitized request.
                const request = Object.freeze(
                    this.getSanitizedUpdateRequest(target)
                );
                // Send the response.
                const response = await organizations.updateOrganizationById(
                    request
                );
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (organization) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'edit-success',
                            content: 'Updated 1 organization successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the page.
                        this.refreshIndex();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'edit-error',
                            // @ts-ignore
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Update an existing organization resource on the server.
             * @param {Pick<globalThis.Organization.Target, 'id'>} target
             */
            this.deleteResource = async (target) => {
                console.log(`[delete::resource] <${target.id}>`);
                // Send the response.
                const response = await organizations.deleteOrganizationById(
                    target
                );
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (organization) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'delete-success',
                            content: 'Deleted 1 organization successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the page.
                        this.refreshIndex();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'delete-error',
                            // @ts-ignore
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Download a CSV file with the specified filename.
             * @param {string} filename Filename.
             * @param {string[][]} content 2D-array containing string array rows. Assumes header is included.
             */
            this.downloadCSV = (filename, content) => {
                console.log(`[download::csv] => ${filename}`);
                // Prepare metadata information.
                const id = encodeURIComponent(filename);
                const timestamp = new Date().toISOString();
                const filetag = `${filename}-${timestamp}.csv`;

                // Prepare the protocol.
                const protocol = 'data:text/csv;charset=utf-8,';
                const lines = content.map((row) => row.join(','));
                const body = lines.join('\r\n');
                const encodedUri = protocol + encodeURIComponent(body);

                // Create the element.
                const element = document.createElement('a');
                element.setAttribute('id', id);
                element.setAttribute('href', encodedUri);
                element.setAttribute('download', filetag);
                document.body.appendChild(element);

                // Download the data.
                element.click();

                // Delete the element.
                document.body.removeChild(element);
            };

            /**
             * Download user information from all known organizations.
             * @param {Organization.Model[]} targets
             */
            this.downloadUsers = async (targets) => {
                console.log(
                    `[download::users] Searching ${targets?.length} organization(s)...`
                );
                const content = {
                    filename: `eclimatenotebook-users`,
                    data: [],
                };

                /**
                 * Get the shared attributes from the organization.
                 * @param {Organization.Model} organization
                 * @param {Subscription.Model} subscription
                 */
                const extractSharedAttributes = (
                    organization,
                    subscription
                ) => {
                    return {
                        // Organization details.
                        organization_id: organization.id,
                        organization_name: organization.name,
                        organization_billing_email: organization.email?.billing,
                        organization_contact_email: organization.email?.contact,
                        // Subscription details.
                        subscription_id: subscription?.id,
                        subscription_plan_id: subscription?.planId,
                        subscription_plan_name: subscription?.plan?.name,
                        subscription_max_users: subscription?.maxUsers,
                        subscription_max_locations: subscription?.maxLocations,
                        subscription_expire_at:
                            subscription?.expireAt?.toISOString(),
                    };
                };

                /**
                 * Get the specific attributes from the user.
                 * @param {User.Model} user
                 */
                const extractUserAttributes = (user) => {
                    return {
                        user_id: user.id,
                        user_name: user.username,
                        user_firstname: user.firstName,
                        user_lastname: user.lastName,
                        user_email: user.email,
                        user_last_login_at: user.lastLoginAt?.toISOString(),
                    };
                };

                /**
                 * Map organization users to rows, with a given starting index.
                 * @param {Organization.Model} organization
                 * @param {number} index
                 * @returns {Promise<void>}
                 */
                const mapOrganizationUsersToRows = async (
                    organization,
                    index
                ) => {
                    if (!organization) {
                        return;
                    }

                    const shared = extractSharedAttributes(
                        organization,
                        organization?.currentSubscription
                    );
                    const response = await organizations.fetchOrganizationUsers(
                        organization
                    );
                    response.match({
                        Ok: (users) => {
                            users.forEach((user, userIndex) => {
                                // Prepare the next row.
                                const row = {
                                    id: index + userIndex,
                                    ...shared,
                                    ...extractUserAttributes(user),
                                };

                                // Append to the row collector.
                                content.data.push(row);
                            });
                        },
                        Err: (e) => {
                            throw e;
                            // this.sendErrorAlert({
                            //     id: 'download-error',
                            //     title: e?.title,
                            //     messages: e?.messages,
                            //     dismissable: true,
                            //     ttl: 10000,
                            // });
                        },
                    });
                };

                // Resolve all promises, populating the row data.
                await Promise.all(targets.map(mapOrganizationUsersToRows));

                // Map the data into a CSV payload.
                /**
                 * @type {{
                 *  id: integer,
                 *  user_id: integer, user_name: string, user_firstname: string, user_lastname: string, user_email: string, user_last_login_at: string,
                 *  organization_id: integer, organization_name: string, organization_billing_email: string, organization_contact_email: string,
                 *  subscription_id: integer, subscription_plan_id: integer, subscription_plan_name: string, subscription_max_users: integer, subscription_max_locations: integer,
                 *  subscription_expires_at: string
                 * }[]}
                 **/
                const payload = content.data;
                if (payload.length == 0) {
                    // Nothing to download.
                    this.sendErrorAlert({
                        id: 'download-error',
                        title: 'No Data',
                        messages: ['There are no users in this organization.'],
                        dismissable: true,
                        ttl: 10000,
                    });
                }

                // Convert the array of objects to a CSV.
                const headers = {
                    id: '#',
                    user_id: 'User ID',
                    user_name: 'Username',
                    user_email: 'User Email',
                    user_last_login_at: 'Last Login Date',
                    organization_id: 'Organization ID',
                    organization_name: 'Institution',
                    organization_billing_email: 'Billing Email',
                    organization_contact_email: 'Contact Email',
                    subscription_id: 'Subscription ID',
                    subscription_plan_id: 'Subscription Plan ID',
                    subscription_plan_name: 'Subscription Plan Name',
                    subscription_expires_at: 'Subscription Expiration Date',
                };

                /** @type {Array<keyof headers>} */
                const keys = /** @type {Array<*>} */ (Object.keys(headers));
                const rows = payload.map((row) => {
                    return keys.map((key) => `${row[key]}`);
                });

                // Download the data.
                const data = [Object.values(headers)].concat(rows);
                this.downloadCSV(content.filename, data);
            };

            /**
             * Download account information from all known organizations.
             * @param {Organization.Model[]} targets
             */
            this.downloadAccounts = async (targets) => {
                console.log(
                    `[download::accounts] Searching ${targets?.length} organization(s)...`
                );
                const content = {
                    filename: `eclimatenotebook-accounts`,
                    data: [],
                };

                /**
                 * Get the shared attributes from the organization.
                 * @param {Organization.Model} organization
                 * @param {Subscription.Model} subscription
                 */
                const extractSharedAttributes = (
                    organization,
                    subscription
                ) => {
                    return {
                        // Organization details.
                        organization_id: organization.id,
                        organization_name: organization.name,
                        organization_billing_email: organization.email?.billing,
                        organization_contact_email: organization.email?.contact,
                        // Subscription details.
                        subscription_id: subscription?.id,
                        subscription_plan_id: subscription?.planId,
                        subscription_plan_name: subscription?.plan?.name,
                        subscription_max_users: subscription?.maxUsers,
                        subscription_max_locations: subscription?.maxLocations,
                        subscription_expire_at:
                            subscription?.expireAt?.toISOString(),
                    };
                };

                /**
                 * Get the specific attributes from the account.
                 * @param {Account.Model} account
                 */
                const extractAccountAttributes = (account) => {
                    return {
                        account_id: account.id,
                        account_name: account.name,
                        account_number_of_locations:
                            account?.numberOfLocations ?? 0,
                        account_number_of_stations:
                            account?.numberOfStations ?? 0,
                        account_last_upload_at:
                            account?.lastUploadAt?.toISOString(),
                    };
                };

                /**
                 * Map organization accounts to rows, with a given starting index.
                 * @param {Organization.Model} organization
                 * @param {number} index
                 * @returns {Promise<void>}
                 */
                const mapOrganizationAccountsToRows = async (
                    organization,
                    index
                ) => {
                    if (!organization) {
                        return;
                    }

                    const shared = extractSharedAttributes(
                        organization,
                        organization?.currentSubscription
                    );
                    const response =
                        await organizations.fetchOrganizationAccounts(
                            organization
                        );
                    response.match({
                        Ok: (accounts) => {
                            accounts.forEach((account, accountIndex) => {
                                // Prepare the next row.
                                const row = {
                                    id: index + accountIndex,
                                    ...shared,
                                    ...extractAccountAttributes(account),
                                };

                                // Append to the row collector.
                                content.data.push(row);
                            });
                        },
                        Err: (e) => {
                            throw e;
                            // this.sendErrorAlert({
                            //     id: 'download-error',
                            //     title: e?.title,
                            //     messages: e?.messages,
                            //     dismissable: true,
                            //     ttl: 10000,
                            // });
                        },
                    });
                };

                // Resolve all promises, populating the row data.
                await Promise.all(targets.map(mapOrganizationAccountsToRows));

                // Map the data into a CSV payload.
                /**
                 * @type {{
                 *  id: integer,
                 *  account_id: integer, account_name: string, account_number_of_locations: number, account_number_of_stations: number, account_last_upload_at: string,
                 *  organization_id: integer, organization_name: string, organization_billing_email: string, organization_contact_email: string,
                 *  subscription_id: integer, subscription_plan_id: integer, subscription_plan_name: string, subscription_max_users: integer, subscription_max_locations: integer,
                 *  subscription_expires_at: string
                 * }[]}
                 **/
                const payload = content.data;
                if (payload.length == 0) {
                    // Nothing to download.
                    this.sendErrorAlert({
                        id: 'download-error',
                        title: 'No Data',
                        messages: [
                            'There are no accounts in this organization.',
                        ],
                        dismissable: true,
                        ttl: 10000,
                    });
                }

                // Convert the array of objects to a CSV.
                const headers = {
                    id: '#',
                    account_id: 'Account ID',
                    account_name: 'Account Name',
                    organization_id: 'Organization ID',
                    organization_name: 'Institution',
                    organization_billing_email: 'Billing Email',
                    organization_contact_email: 'Contact Email',
                    subscription_id: 'Subscription ID',
                    subscription_plan_id: 'Subscription Plan ID',
                    subscription_plan_name: 'Subscription Plan Name',
                    subscription_expires_at: 'Subscription Expiration Date',
                };

                /** @type {Array<keyof headers>} */
                const keys = /** @type {Array<*>} */ (Object.keys(headers));
                const rows = payload.map((row) => {
                    return keys.map((key) => `${row[key]}`);
                });

                // Download the data.
                const data = [Object.values(headers)].concat(rows);
                this.downloadCSV(content.filename, data);
            };
        }

        // ==== MANAGER ACTIONS ====
        {
            // Get the required submodules.
            const { state, constants } = this.context;

            /**
             * Refresh the resource index.
             */
            this.refreshIndex = async () => {
                // Clear related alerts.
                this.clearAlerts([
                    'refresh-success',
                    'refresh-warning',
                    'refresh-error',
                ]);
                try {
                    // Update the resource index for the table data.
                    this.startLoading('[refresh::organizations]');
                    // Start refreshing.
                    state.refreshing.value = true;
                    // Temporarily clear out the table.
                    state.rowData.value = [];
                    // Fetch collection of organizations.
                    const response = await organizations.fetchOrganizations();
                    // Handle the response.
                    if (response.isErr) throw response.error;
                    // Create row data models from resource index.
                    const index = response.isOk ? response.value : [];
                    // Trigger the index refresh.
                    this.refreshed({ index });
                    if (constants.IsDebug) {
                        // Send a success alert.
                        this.sendSuccessAlert({
                            id: 'refresh-success',
                            content: `Fetched ${index.length} organization(s) successfully.`,
                            dismissable: false,
                            ttl: 5000,
                        });
                    }
                } catch (e) {
                    // Send error event.
                    this.handleClientError('refresh-error', e);
                    // Stop refreshing.
                    state.refreshing.value = false;
                } finally {
                    // Update the loading status.
                    this.stopLoading('[refresh::organizations]');
                }
            };
        }
    }

    /**
     * Define utility functions.
     */
    defineUtilities() {
        // ==== ERROR HANDLING ====
        {
            const { AlertIDs } = this.context.constants;
            const ids = /** @type {Array<keyof AlertIDs>} */ (
                Object.keys(AlertIDs)
            );

            /**
             * Handle the passed client error.
             * @param {keyof Constants['AlertIDs']} id The alert tag.
             * @param {Client.Error<Client.ErrorType>} err
             */
            this.handleClientError = (id, err) => {
                console.warn(`[${id}] ${err?.type}.`);
                this.sendErrorAlert({
                    id,
                    title: err?.title ?? 'Error',
                    messages: err?.messages ?? [
                        'An unexpected error occurred.',
                    ],
                    dismissable: true,
                    ttl: 10000,
                });
            };

            /**
             * Expire alerts after a scheduled delay.
             *
             * @param {(keyof Constants['AlertIDs'])[]} [targets]
             * @param {number} [delayInMilliseconds]
             */
            this.queueClearAlerts = async (
                targets = ids,
                delayInMilliseconds = 5000
            ) => {
                await promiseTimeout(delayInMilliseconds);
                this.clearAlerts(targets);
            };
        }

        // ==== UTILITY FUNCTIONS ====
        {
            // Get the state.
            const { state } = this.context;

            /**
             * Compare two values.
             * @type {AgGrid.ColumnDef['comparator']}
             */
            this.compareByLowercase = (valueA, valueB, nodeA, nodeB) => {
                /** @type {string} */
                const valueALower = valueA.toLowerCase().trim();
                /** @type {string} */
                const valueBLower = valueB.toLowerCase().trim();
                // Return comparison value.
                return valueALower.localeCompare(valueBLower, 'en');
            };

            /**
             * Format the date value for the handled field.
             * @type {AgGrid.ValueFormatterFunc<string>}
             */
            this.formatDate = (params) => {
                // Get the value.
                const date = Maybe.of(params.value).map((dt) => new Date(dt));
                // Conditionally execute based on if row was found.
                const formatted = date.match({
                    Just: (dt) => {
                        return dt.toLocaleDateString('en-ca', {
                            timeZone: state.displayTimezone.value ?? 'UTC',
                        });
                    },
                    Nothing: () => 'No date provided.',
                });
                // Return the value.
                return formatted;
            };
        }

        // ==== GRID FUNCTIONS ====
        {
            // Get the required submodules.
            const { state } = this.context;

            /**
             * Get row data by its index in the array.
             * @param {number} index
             */
            this.findRowByIndex = (index) => {
                const position = Maybe.of(index).unwrapOr(-1);
                const row = state.rowData.value[position];
                return Maybe.of(row);
            };

            /**
             * Handle switch organization button click.
             *
             * @param {MouseEvent} event Click event.
             * @param {number} index Node index.
             */
            this.handleSwitchOrganizationButton = async (event, index) => {
                // Clear all existing alerts.
                this.clearAlerts();
                // Find the row by index.
                const row = this.findRowByIndex(index);
                // Conditionally execute based on if row was found.
                row.match({
                    Just: (target) => this.switchOrganization(target),
                    Nothing: () =>
                        this.handleClientError('switch-error', {
                            type: 'Error',
                            title: 'Cannot switch organizations.',
                            messages: [`No row exists at position ${index}.`],
                            timestamp: new Date(),
                        }),
                });
            };

            /**
             * Handle the click event from the edit button.
             *
             * @param {MouseEvent} event Click event.
             * @param {number} index Node index.
             */
            this.handleEditButton = (event, index) => {
                // Clear all existing alerts.
                this.clearAlerts();
                // Find the row by index.
                const row = this.findRowByIndex(index);
                // Conditionally execute based on if row was found.
                row.match({
                    Just: (target) =>
                        this.clickEditButton({ event, id: target.id }),
                    Nothing: () =>
                        this.handleClientError('edit-error', {
                            type: 'Error',
                            title: 'Cannot edit organization.',
                            messages: [`No row exists at position ${index}.`],
                            timestamp: new Date(),
                        }),
                });
            };

            /**
             * Handle the click event from the delete button.
             *
             * @param {MouseEvent} event Click event.
             * @param {number} index Node index.
             */
            this.handleDeleteButton = (event, index) => {
                // Clear all existing alerts.
                this.clearAlerts();
                // Find the row by index.
                const row = this.findRowByIndex(index);
                // Conditionally execute based on if row was found.
                row.match({
                    Just: (target) =>
                        this.clickDeleteButton({ event, id: target.id }),
                    Nothing: () =>
                        this.handleClientError('delete-error', {
                            type: 'Error',
                            title: 'Cannot delete organization.',
                            messages: [`No row exists at position ${index}.`],
                            timestamp: new Date(),
                        }),
                });
            };

            /**
             * Get keyed column definitions.
             * @returns {Readonly<Record<String, AgGrid.ColumnDef>>}
             */
            this.getColumnSchema = () => {
                return {
                    /** @type {AgGrid.ColumnDef} Table icons with button actions. */
                    icons: {
                        headerName: '',
                        field: 'actions',
                        cellRenderer: OrganizationManagerTableIcons,
                        lockPosition: true,
                        filter: false,
                        maxWidth: 120,
                        cellRendererParams: {
                            handleEdit: this.handleEditButton,
                            handleDelete: this.handleDeleteButton,
                            handleSwitchOrganization:
                                this.handleSwitchOrganizationButton,
                        },
                    },
                    id: {
                        headerName: 'Organization ID',
                        field: 'id',
                    },
                    name: {
                        headerName: 'Organization Name',
                        field: 'name',
                        comparator: this.compareByLowercase,
                        autoHeight: true,
                        sortable: true,
                        sort: 'asc',
                        unSortIcon: true,
                    },
                    primaryEmail: {
                        headerName: 'Primary Admin Email',
                        field: 'primaryEmail',
                        width: 100,
                    },
                    subscriptionLevel: {
                        headerName: 'Subscription Level',
                        field: 'subscriptionLevel',
                        maxWidth: 150,
                    },
                    numberOfAccounts: {
                        headerName: '# of Accounts',
                        field: 'numberOfAccounts',
                        maxWidth: 100,
                    },
                    numberOfUsers: {
                        headerName: '# of Users',
                        field: 'numberOfUsers',
                        maxWidth: 100,
                    },
                    numberOfLocations: {
                        headerName: '# of Locations',
                        field: 'numberOfLocations',
                        maxWidth: 100,
                    },
                    expirationDate: {
                        headerName: `Expiration Date (${state.timeZoneAbbreviation.value})`,
                        field: 'expirationDate',
                        valueFormatter: this.formatDate,
                        maxWidth: 320,
                        sortable: true,
                        unSortIcon: true,
                    },
                };
            };

            /**
             * Get column definitions in ordered array.
             * @returns {AgGrid.ColumnDef[]}
             */
            this.getColumnDefs = () => {
                const schema = this.getColumnSchema();
                return [
                    schema.icons,
                    schema.name,
                    schema.primaryEmail,
                    schema.subscriptionLevel,
                    schema.numberOfAccounts,
                    schema.numberOfUsers,
                    schema.numberOfLocations,
                    schema.expirationDate,
                ];
            };
        }
    }
}

// <!-- MANAGER MODULE -->

/**
 * Orchestrates the organization management features.
 */
class OrganizationManager {
    /**
     * Instantiate a new manager.
     * @param {Parameters<OrganizationManager['boot']>[0]} [props] Composable parameters.
     */
    constructor(props = {}) {
        // Bind or inject services required by any submodules.
        this.boot(props);
        // Define the submodule interface.
        this.defineInterface();
        // Register the event listeners.
        this.registerEventListeners();
        // Register any watchers.
        this.registerWatchers();
    }

    /**
     * Assign the context.
     * @param {Object} services Composable parameters.
     * @param {Vuex.Store<ECNBState>} [services.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {Router.Instance} [services.router] Optional router to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [services.alerts] Alerts composable.
     * @param {ReturnType<useAgGridVue>} [services.grid] AgGrid composable.
     */
    boot({ store, router, alerts, grid }) {
        /** @type {Vuex.Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {Router.Instance} */
        this.router = router ?? useRouter();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAgGridVue>} */
        this.grid = grid ?? useAgGridVue();
    }

    /**
     * Initializes the submodules needed to fulfill the manager's tasks.
     */
    defineInterface() {
        // Define submodules.
        this.constants = new Constants();
        this.events = new Events();
        this.state = new State(this);
        this.methods = new Methods(this);
    }

    /**
     * Register the internal event listener callbacks.
     */
    registerEventListeners() {
        // Provide access to state and methods.
        const { state, methods: _ } = this;

        // ==== STATUS ====
        {
            _.onLoadStarted((tag) => {
                console.time(tag);
                state.loading.value = true;
            });

            _.onLoadStopped((tag) => {
                state.loading.value = false;
                console.timeEnd(tag);
            });
        }

        // ==== ALERTS ====
        {
            const { clearAlert, createAlert, pushAlert } = this.alerts.methods;

            // Clear the specified alerts.
            _.onClearAlerts((ids) => {
                ids.forEach((id) => clearAlert(id));
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertSuccess((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'success',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertWarning((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'warning',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertError((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'error',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });
        }

        // ==== MODAL DIALOG ====
        {
            // Sets the active modal.
            _.onModalOpened(({ id }) => {
                state.modal.value = id;
            });

            // Closes the active modal if it matches the given id.
            _.onModalClosed(({ id }) => {
                if (state.modal.value === id) {
                    state.modal.value = null;
                }
            });
        }

        // ==== FORM ACTIONS ====
        {
            // Sets the appropriate target and opens the appropriate dialog.
            _.onAddButtonClicked(({ event }) => {
                console.log(`[click::add]`, event);
                // Set the target and open the modal.
                state.targets.add.value = {
                    ...this.constants.DefaultOrganizationTarget,
                };
                _.openModal({ id: 'addOrganization' });
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onEditButtonClicked(async ({ event, id }) => {
                console.log(`[click::edit] <${id}>`, event);
                // Request the organization target by id.
                const response = await organizations.fetchOrganizationById({
                    id,
                });

                // Error handling.
                if (response.isErr) {
                    const err = response.error;
                    _.sendErrorAlert({
                        id: 'edit-error',
                        title: err?.type,
                        content: err?.title,
                        messages: err?.messages,
                        dismissable: true,
                        ttl: 7000,
                    });
                    return;
                }

                // Unwrap the response to get the appropriate target.
                const model = response.isOk ? response.value : null;

                /** @type {globalThis.Organization.Target} */
                const target = {
                    id: model.id,
                    name: model.name,
                    address: model.address,
                    email: model.email,
                    reminderFrequency: model.reminderFrequency,
                    timezone: model.timezone,
                    temperatureScale: model.temperatureScale,
                    treeLabels: model.treeLabels,
                    currentSubscription: null,
                };

                // Hydrate default tree labels.
                if (target) {
                    target.treeLabels = target.treeLabels ?? ['', '', '', ''];
                    target.currentSubscription = {
                        ...model.currentSubscription,
                        plan: model.currentSubscription?.plan ?? null,
                    };
                }

                // Set the target.
                state.targets.edit.value = target;

                // Open the modal.
                _.openModal({ id: 'editOrganization' });
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onDeleteButtonClicked(async ({ event, id }) => {
                console.log(`[click::delete] <${id}>`, event);
                state.targets.delete.value = { id };
                _.openModal({ id: 'deleteOrganization' });
            });

            // Closes the appropriate dialog and clears the target.
            _.onAddCanceled((event) => {
                console.log(`[cancel::add]`, event?.reason);
                _.closeModal({ id: 'addOrganization' });
                state.targets.add.value = null;
            });

            // Closes the appropriate dialog and clears the target.
            _.onEditCanceled((event) => {
                console.log(`[cancel::edit]`, event?.reason);
                _.closeModal({ id: 'editOrganization' });
                state.targets.edit.value = null;
            });

            // Closes the appropriate dialog and clears the target.
            _.onDeleteCanceled((event) => {
                console.log(`[cancel::delete]`, event?.reason);
                _.closeModal({ id: 'deleteOrganization' });
                state.targets.delete.value = null;
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onAddSubmitted(async (event) => {
                console.log(
                    `[submit:add] <${event?.target?.organizationName}>`
                );
                try {
                    // Create new resource using the target data.
                    _.startLoading('submit:add');
                    _.closeModal({ id: 'addOrganization' });
                    await _.createResource(event?.target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:add');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onEditSubmitted(async (event) => {
                console.log(
                    `[submit:edit] <${event?.target?.organizationName}> [${event?.target?.organizationId}]`
                );
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:edit');
                    _.closeModal({ id: 'editOrganization' });
                    await _.updateResource(event?.target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:edit');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onDeleteSubmitted(async () => {
                const target = state.targets.delete.value;
                console.log(`[submit:delete] <${target?.id}>`);
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:delete');
                    _.closeModal({ id: 'deleteOrganization' });
                    await _.deleteResource(target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:delete');
                }
            });

            // Begins the download.
            _.onDownloadButtonClicked(async ({ event }) => {
                console.log(`[click::download] All Data`, event);
                try {
                    // Update existing resource using the target data.
                    _.startLoading('download:data');
                    _.downloadData();
                } finally {
                    // Update the loading status.
                    _.stopLoading('download:data');
                }
            });
        }

        // ==== MANAGER ACTIONS ====
        {
            // Handle download.
            _.onDownloadData(async () => {
                console.log(`[download::data]`);

                // Fetch all organizations.
                const response = await organizations.fetchOrganizations();

                /** @type {Organization.Model[]} */
                response.match({
                    Ok: (models) => {
                        Promise.all([
                            _.downloadUsers(models),
                            _.downloadAccounts(models),
                        ]);
                    },
                    Err: (e) => {
                        _.sendErrorAlert({
                            id: 'download-error',
                            title: e?.title,
                            messages: e?.messages,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            });

            // Handle switching an organization.
            _.onSwitchOrganization(async ({ id }) => {
                console.log(`[switch::organization] => ${id}`);

                // Get the current organization.
                const response = await organizations.fetchOrganizationById({
                    id,
                });

                // Conditionally execute code.
                response.match({
                    Ok: (organization) => {
                        this.store.commit(
                            'setCurrentOrganization',
                            organization
                        );
                        this.store.commit('clearCurrentAccount');
                        _.redirect({ to: '/select-account' });
                    },
                    Err: (e) => {
                        _.sendErrorAlert({
                            id: 'switch-error',
                            title: e?.title,
                            messages: e?.messages,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            });

            // Redirect the page using the specified location.
            _.onRedirect(({ to }) => {
                console.log(`[redirect]`, to);
                this.router.push(to);
            });

            // Refresh the table data.
            _.onRefreshed(({ index }) => {
                // Update the table with the row data models.
                const rows = index.map(Organization.Model.createRowModel);
                // Assign the row models.
                state.rowData.value = rows;
                // Stop refreshing once assigned update.
                state.refreshing.value = false;
            });
        }
    }

    /**
     * Register the following watch effects.
     */
    registerWatchers() {
        // Provide access to state and methods.
        const { state } = this;

        // ==== TIMEZONE ====
        {
            watchEffect(() => {
                // Update the current display timezone based on the selected account.
                state.displayTimezone.value =
                    state.currentAccount.value?.timezone ?? 'UTC';
            });
        }
    }
}

// <!-- COMPOSABLE -->

/**
 * Composable feature for managing the organization admin view-model state.
 */
export const useOrganizationManager = () => {
    const manager = new OrganizationManager();
    return manager;
};

// <!-- DEFAULT -->
export default useOrganizationManager;
