// <!-- ENUMS -->
import { TemperatureScale } from '@/enums';

// <!-- UTILITIES -->
import { Option } from '@/utils/options';

/**
 * @typedef {Option<Temperature.Label | 'Select Temperature', Temperature.Scale | 'placeholder'>} TemperatureScaleOption
 */

/**
 * Compose options for a select input dialog.
 * @returns {TemperatureScaleOption[]}
 */
export const useTemperatureOptions = () => {
    /** @type {TemperatureScaleOption} */
    const prompt = new Option({
        label: 'Select Temperature',
        value: 'placeholder',
        attrs: { disabled: true },
    });
    /** @type {TemperatureScaleOption[]} */
    const options = Option.fromRecord(TemperatureScale._dictionary);
    return [prompt, ...options];
};
