// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Account } from '@/models/v2/accounts';
import { AccountPayload } from '@/payloads/v2/accounts';
import { Organization } from '@/models/v2/organizations';
import { User } from '@/models/v2/users';
import { UserPayload } from '@/payloads/v2/users';

// <!-- ROUTES -->

/** @type {Readonly<globalThis.Account.Routes>} */
const URI = {
    index: () => `accounts`,
    show: (id) => `accounts/${id}`,
    create: () => `accounts`,
    update: (id) => `accounts/${id}`,
    delete: (id) => `accounts/${id}`,
    organization: (id) => `accounts/${id}/organization`,
    locations: (id) => `accounts/${id}/locations`,
    stations: (id) => `accounts/${id}/stations`,
    notes: (id) => `accounts/${id}/notes`,
    standards: (id) => `accounts/${id}/standards`,
};

// <!-- ENDPOINTS -->

/**
 * Get all accounts available in the database.
 * @type {globalThis.Account.API.FetchIndex}
 */
export const fetchAccounts = async (config) => {
    try {
        /** @type {globalThis.Account.Response.FetchedIndex} */
        const response = await axios().get(URI.index(), config);
        const payloads = response.data.data.map(
            (item) => new AccountPayload(item)
        );
        const models = payloads.map((payload) => new Account(payload));
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get an account by its identifier.
 * @type {globalThis.Account.API.FetchResource}
 */
export const fetchAccountById = async (request, config) => {
    try {
        /** @type {globalThis.Account.Response.FetchedResource} */
        const response = await axios().get(URI.show(request.id), config);
        const payload = new AccountPayload(response.data.account);
        const model = new Account(payload);
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Create a single account.
 * @type {globalThis.Account.API.CreateResource}
 */
export const createAccount = async (request, config) => {
    try {
        /** @type {globalThis.Account.Response.CreatedResource} */
        const response = await axios().post(URI.create(), request, config);
        const payload = new AccountPayload(response.data.account);
        const model = new Account(payload);
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Update a single account by id.
 * @type {globalThis.Account.API.UpdateResource}
 */
export const updateAccountById = async (request, config) => {
    try {
        /** @type {globalThis.Account.Response.UpdatedResource} */
        const response = await axios().patch(
            URI.update(request.id),
            request,
            config
        );
        const payload = new AccountPayload(response.data.account);
        const model = new Account(payload);
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Delete an account by id.
 * @type {globalThis.Account.API.DeleteResource}
 */
export const deleteAccountById = async (request, config) => {
    try {
        /** @type {globalThis.Account.Response.DeletedResource} */
        const response = await axios().delete(URI.delete(request.id), config);
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Get an account by its identifier.
 * @type {globalThis.Account.API.FetchLocations}
 */
export const fetchAccountLocations = async (request, config) => {
    throw new Error('Not implemented yet.');
    // try {
    //     /** @type {globalThis.Account.Response.FetchedLocations} */
    //     const response = await axios().get(URI.locations(request.id), config);
    //     const payloads = response.data?.locations.map(
    //         (item) => new LocationPayload(item)
    //     );
    //     const models = payloads.map((payload) => new Account(payload));
    //     const result = Result.ok(models);
    //     return result;
    // } catch (e) {
    //     return e instanceof AxiosError
    //         ? ErrorResult.fromAxiosError(e, request)
    //         : ErrorResult.from(e);
    // }
};

/** @type {Readonly<globalThis.Account.API>} */
export default {
    fetchAccounts,
    fetchAccountById,
    createAccount,
    updateAccountById,
    deleteAccountById,
    fetchAccountOrganization: null,
    fetchAccountLocations,
    fetchAccountStations: null,
    fetchAccountNotes: null,
    fetchAccountStandards: null,
};
