<template>
    <FormKit
        type="form"
        v-model="formData"
        #default="context"
        :actions="false"
        :config="config"
    >
        <PageIntro v-if="isEditing">
            <PageDescription>
                View and edit subscription details and settings.
            </PageDescription>
            <div class="flex flex-row-reverse">
                <ModalButton
                    theme="primary"
                    label="Save"
                    @click.prevent="() => clickSubmitButton(context)"
                    :disabled="isLoading"
                />
                <ModalButton
                    theme="white"
                    label="Cancel"
                    @click.prevent="() => clickCancelButton()"
                    :disabled="isLoading"
                />
            </div>
        </PageIntro>
        <div class="max-w-7xl">
            <!-- PRIMARY INFORMATION -->
            <FormSection>
                <template #default>
                    <!-- ORGANIZATION NAME -->
                    <FormKit
                        type="text"
                        label="Organization Name"
                        name="name"
                        placeholder="Institution"
                        :disabled="!isEditing"
                        :innerClass="isEditing ? '' : 'border-none px-3'"
                        validation="required:trim|length:1"
                        outerClass="col-span-1 px-3"
                    />
                    <!-- SUBSCRIPTION NAME -->
                    <FormKit
                        type="text"
                        label="Subscription Level"
                        name="planName"
                        placeholder="Free"
                        :readonly="true"
                        innerClass="border-none px-3"
                        outerClass="w-full px-4 whitespace-nowrap"
                    >
                        <template #label>
                            <div
                                class="mb-1 font-bold text-sm disabled:flex flex-row"
                            >
                                <label class="text-black">
                                    Subscription Level
                                </label>
                                <a
                                    class="text-primary-400"
                                    href="#"
                                    target="_blank"
                                    @click.prevent="clickUpgradeButton"
                                >
                                    <span class="pl-1"> (Upgrade) </span>
                                </a>
                            </div>
                        </template>
                    </FormKit>
                    <!-- EXPIRATION DATE -->
                    <FormKit
                        type="text"
                        label="Expires On"
                        name="expireAt"
                        placeholder="No Expiration Date"
                        :readonly="true"
                        :ignore="true"
                        :preserve="true"
                        :value="expirationDate"
                        innerClass="border-none px-3"
                        outerClass="w-full px-4 whitespace-nowrap"
                    >
                        <template #label>
                            <div
                                class="mb-1 font-bold text-sm disabled:flex flex-row"
                            >
                                <label class="text-black"> Expires </label>
                                <a
                                    class="text-primary-400"
                                    href="#"
                                    target="_blank"
                                    @click.prevent="clickExtendButton"
                                >
                                    <span class="pl-1"> (Extend) </span>
                                </a>
                            </div>
                        </template>
                    </FormKit>
                </template>
            </FormSection>
            <!-- REGION INFORMATION -->
            <FormSection class="pb-4">
                <template #default>
                    <FormKit
                        type="group"
                        name="address"
                    >
                        <!-- COUNTRY -->
                        <div class="w-full">
                            <FormKit
                                type="select"
                                label="Country"
                                name="country"
                                placeholder="Select Country"
                                :options="countries"
                                :disabled="!isEditing"
                                :preserve="true"
                                :validation="[
                                    ['required', 'trim'],
                                    ['*+not', 'placeholder'],
                                ]"
                                :validation-messages="{
                                    not: 'Please select a valid country.',
                                }"
                                :inputClass="
                                    isEditing
                                        ? ''
                                        : '$reset w-full h-10 border px-3 rounded-lg text-base placeholder-gray-200 bg-gray-50 border-none disabled:'
                                "
                                outerClass="col-span-1 px-3"
                            />
                        </div>
                        <!-- STATE / PROVINCE -->
                        <div
                            class="w-full sm:pr-2"
                            v-if="isRegionInputVisible && isStateInputVisible"
                        >
                            <FormKit
                                type="select"
                                label="State"
                                name="state"
                                placeholder="Select State"
                                :options="states"
                                :disabled="!isEditing"
                                :preserve="true"
                                :validation="[
                                    ['required', 'trim'],
                                    ['*+not', 'placeholder'],
                                ]"
                                :validation-messages="{
                                    not: 'Please select a valid state.',
                                }"
                                :inputClass="
                                    isEditing
                                        ? ''
                                        : '$reset w-full h-10 border px-3 rounded-lg text-base placeholder-gray-200 bg-gray-50 border-none disabled:'
                                "
                                outerClass="col-span-1 px-3"
                            />
                        </div>
                        <div
                            class="w-full sm:pr-2"
                            v-if="
                                isRegionInputVisible && isProvinceInputVisible
                            "
                        >
                            <FormKit
                                type="select"
                                label="Province"
                                name="state"
                                placeholder="Select Province"
                                :options="provinces"
                                :disabled="!isEditing"
                                :preserve="true"
                                :validation="[
                                    ['required', 'trim'],
                                    ['*+not', 'placeholder'],
                                ]"
                                :validation-messages="{
                                    not: 'Please select a valid state.',
                                }"
                                :inputClass="
                                    isEditing
                                        ? ''
                                        : '$reset w-full h-10 border px-3 rounded-lg text-base placeholder-gray-200 bg-gray-50 border-none disabled:'
                                "
                                outerClass="col-span-1 px-3"
                            />
                        </div>
                        <!-- CITY-->
                        <div class="w-full sm:pr-2">
                            <FormKit
                                type="text"
                                label="City"
                                name="city"
                                validation="required:trim|length:1"
                                :disabled="!isEditing || isCityInputDisabled"
                                :innerClass="
                                    isEditing ? '' : 'border-none px-3'
                                "
                                outerClass="col-span-1 px-3"
                            />
                        </div>
                    </FormKit>
                </template>
            </FormSection>
            <!-- SUBSCRIPTION DETAILS -->
            <FormSection class="pb-4">
                <template #default>
                    <!-- USER CAPACITY -->
                    <FormKit
                        type="number"
                        label="Users"
                        name="maxUsers"
                        placeholder="Unlimited"
                        :readonly="true"
                        innerClass="border-none px-3"
                        inputClass="bg-gray-50 border-none "
                        outerClass="col-span-1 px-3"
                    />
                    <!-- LOCATION CAPACITY -->
                    <FormKit
                        type="number"
                        label="Locations"
                        name="maxLocations"
                        placeholder="Unlimited"
                        :readonly="true"
                        innerClass="border-none px-3"
                        inputClass="bg-gray-50 border-none "
                        outerClass="col-span-1 px-3"
                    />
                </template>
            </FormSection>
            <!-- CONTACT INFORMATION -->
            <FormSection class="pb-4">
                <template #default>
                    <FormKit
                        type="group"
                        name="email"
                    >
                        <!-- CONTACT EMAIL -->
                        <FormKit
                            type="text"
                            label="Primary Email"
                            name="contact"
                            placeholder="contact@example.com"
                            :disabled="!isEditing"
                            :innerClass="isEditing ? '' : 'border-none px-3'"
                            validation="required:trim|length:1"
                            outerClass="col-span-1 px-3"
                        />
                        <!-- BILLING EMAIL -->
                        <FormKit
                            type="text"
                            label="Billing Email"
                            name="billing"
                            placeholder="billing@example.com"
                            :disabled="!isEditing"
                            :innerClass="isEditing ? '' : 'border-none px-3'"
                            validation="required:trim|length:1"
                            outerClass="col-span-1 px-3"
                        />
                        <!-- EMAIL REMINDER FREQUENCY -->
                        <FormKit
                            type="select"
                            label="Reminder Frequency"
                            name="reminderFrequency"
                            placeholder="Never"
                            :options="frequencyOptions"
                            :disabled="!isEditing"
                            :validation="[
                                ['required'],
                                ['*+not', 'placeholder'],
                            ]"
                            :validation-messages="{
                                not: 'Please enter a valid frequency.',
                            }"
                            :inputClass="
                                isEditing
                                    ? ''
                                    : '$reset w-full h-10 border px-3 rounded-lg text-base placeholder-gray-200 bg-gray-50 border-none disabled:'
                            "
                            outerClass="col-span-1 px-3"
                        />
                    </FormKit>
                </template>
            </FormSection>
            <!-- ADDITIONAL INFORMATION -->
            <FormSection class="pb-42">
                <template #default>
                    <!-- TIMEZONE IDENTIFIER -->
                    <FormKit
                        type="select"
                        label="Time Zone"
                        name="timezone"
                        placeholder="UTC"
                        :options="timezoneOptions"
                        :disabled="!isEditing"
                        :validation-rules="{ isValidTimezone }"
                        :validation="[
                            ['required'],
                            ['*+not', 'placeholder'],
                            ['isValidTimezone'],
                        ]"
                        :validation-messages="{
                            not: 'Please enter a valid time zone.',
                        }"
                        :inputClass="
                            isEditing
                                ? ''
                                : '$reset w-full h-10 border px-3 rounded-lg text-base placeholder-gray-200 bg-gray-50 border-none disabled:'
                        "
                        outerClass="col-span-1 px-3"
                    />
                    <!-- TEMPERATURE SCALE -->
                    <FormKit
                        type="select"
                        label="Temperature Scale"
                        name="temperatureScale"
                        placeholder="Fahrenheit (°F)"
                        :options="temperatureOptions"
                        :disabled="!isEditing"
                        :validation="[['required'], ['*+not', 'placeholder']]"
                        :validation-messages="{
                            not: 'Please enter a valid temperature scale.',
                        }"
                        :inputClass="
                            isEditing
                                ? ''
                                : '$reset w-full h-10 border px-3 rounded-lg text-base placeholder-gray-200 bg-gray-50 border-none disabled:'
                        "
                        outerClass="col-span-1 px-3"
                    />
                </template>
            </FormSection>
            <!-- DEFAULT TREE LABELS HEADING -->
            <div class="mt-4 text-base font-bold">Manage Hierarchy</div>
            <div>
                <p
                    v-if="isNARAEnabled"
                    class="text-sm text-gray-500"
                >
                    NARA uses consistent terminology and abbreviations for
                    choices in the four Location Hierarchy levels.
                    <span class="font-bold"
                        >Level 1- Building. Level 2-Administration</span
                    >
                    (AO-Archives Office. FRC-Records Center. PLA-Presidential
                    Library Archives. PLM-Presidential Library Museum).
                    <span class="font-bold">Level 3-Space Type</span>
                    (ST-Storage. WR-Workroom. EX-Exhibition. RR-Research Room).
                    <span class="font-bold">Level 4-Monitoring Site</span>
                    (space name/number or a custom identifier).
                </p>
                <div
                    v-else
                    class="text-sm text-gray-500"
                >
                    Environmental management teams should work collaboratively
                    to develop consistent terminology for each level of the
                    location hierarchy to create a structure that is intuitive
                    to both collections and facilities staff. For example,
                    Second Floor, 2nd Fl., Two, or 2 can all refer to the same
                    floor but will be recognized as different floor levels in
                    the hierarchy.
                    <span class="font-bold"
                        >Determine which terminology is preferred and use it
                        consistently.</span
                    >
                    <p class="pt-2">
                        On this page, labels can be assigned to each hierarchy
                        level, for example Site (level 1), Building (level 2),
                        Floor (level 3), and Room (level 4), to assist with
                        naming conventions and consistency. The labels assigned
                        here will appear on the Add Location page to guide
                        editing of existing locations and naming of new
                        locations in this account.
                    </p>
                </div>
            </div>
            <!-- DEFAULT TREE LABELS SECTION -->
            <FormSection
                class="mt-4"
                :grid="[
                    'grid',
                    'grid-cols-1',
                    'gap-y-2',
                    'gap-x-0',
                    'sm:grid-cols-4',
                ]"
            >
                <template #default>
                    <!-- DEFAULT TREE LABELS LIST -->
                    <FormKit
                        type="list"
                        name="treeLabels"
                    >
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Level 1"
                                placeholder="Site"
                                :disabled="!isEditing"
                                :innerClass="
                                    isEditing ? '' : 'border-none px-3'
                                "
                                :index="0"
                                validation="required:trim|length:1"
                            />
                        </div>
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Level 2"
                                placeholder="Building"
                                :disabled="!isEditing"
                                :innerClass="
                                    isEditing ? '' : 'border-none px-3'
                                "
                                :index="1"
                                validation="required:trim|length:1"
                            />
                        </div>
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Level 3"
                                placeholder="Floor"
                                :disabled="!isEditing"
                                :innerClass="
                                    isEditing ? '' : 'border-none px-3'
                                "
                                :index="2"
                                validation="required:trim|length:1"
                            />
                        </div>
                        <div class="sm:pr-2">
                            <FormKit
                                type="text"
                                label="Level 4"
                                placeholder="Room"
                                :disabled="!isEditing"
                                :innerClass="
                                    isEditing ? '' : 'border-none px-3'
                                "
                                :index="3"
                                validation="required:trim|length:1"
                            />
                        </div>
                    </FormKit>
                </template>
            </FormSection>
        </div>
        <div
            v-if="debug"
            class="mt-4 border-gray-50 bg-gray-50 p-4"
        >
            <code>Form Data:</code>
            <pre>{{ formData }}</pre>
        </div>
    </FormKit>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, computed, ref, onBeforeMount } from 'vue';
    import { createEventHook, computedEager } from '@vueuse/core';

    // <!-- ENUMS -->
    import { CountryName } from '@/enums';

    // <!-- COMPONENTS -->
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- UTILITIES -->
    import clone from 'just-clone';
    import { isValid } from 'date-fns';
    import { isValidTimezone } from '@/utils/timezone';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- COMPOSABLES -->
    import { useRegionSelectors } from '@/hooks/options/useRegionSelectors';
    import { useTimezoneOptions } from '~DataManager/hooks/useTimezoneOptions';
    import { useTemperatureOptions } from '~DataManager/hooks/useTemperatureOptions';
    import { useReminderOptions } from '@/features/data-manager/hooks/useReminderOptions';
    import { states, provinces } from '@/hooks/useStates';
    import { countries } from '@/hooks/useCountries';

    // <!-- HELPERS -->
    /**
     * Define the component state.
     *
     * @param {Object} props
     * @param {Vue.Ref<globalThis.Organization.SubscriptionFormData>} props.source
     */
    const defineState = ({ source }) => {
        /** @type {Vue.Ref<Partial<globalThis.Organization.SubscriptionFormData>>} */
        const initialData = ref({
            ...source.value,
        });

        /** @type {Vue.Ref<Partial<globalThis.Organization.SubscriptionFormData>>} */
        const formData = ref({
            ...initialData.value,
        });

        /** Errors object. */
        const errors = {
            /** Potential email errors. */
            email: ref(''),
        };

        /** @type {Vue.Ref<Partial<import('@formkit/core').FormKitProps>>} */
        const config = ref({
            delay: 250,
            validationVisibility: 'blur',
        });

        return {
            initialData,
            formData,
            errors,
            config,
        };
    };

    /**
     * Define the component computed properties.
     *
     * @param {ReturnType<defineState> & { isLoading: Vue.Ref<boolean> }} props
     */
    const defineComputed = ({ isLoading, formData, planSelector }) => {
        const displayTimezone = formData.value?.timezone ?? 'UTC';

        const expirationDate = computedEager(() => {
            const selectedDate = formData.value?.expireAt ?? null;

            if (!isValid(selectedDate)) {
                return 'No date provided.';
            } else {
                // Format the date.
                return (
                    selectedDate.toLocaleDateString('en-ca', {
                        timeZone: displayTimezone,
                    }) + ` (${displayTimezone})`
                );
            }
        });

        const isCityInputDisabled = computed(() => {
            const selectedValues = formData.value?.address;
            return (
                selectedValues?.country === '' ||
                selectedValues?.country === 'Select Country' ||
                selectedValues?.country === null
            );
        });

        const isStateInputVisible = computed(() => {
            const selectedValues = formData.value?.address;
            return selectedValues?.country === CountryName.ByISO3.USA;
        });

        const isProvinceInputVisible = computed(() => {
            const selectedValues = formData.value?.address;
            return selectedValues?.country === CountryName.ByISO3.CAN;
        });

        const isRegionInputVisible = computed(() => {
            return isStateInputVisible.value || isProvinceInputVisible.value;
        });

        const isEnterpriseSelected = computed(() => {
            const availableOptions = planSelector.state.options.value;
            const currentOption = availableOptions.find(
                (o) => o.value === `${formData.value?.planId}`
            );
            return currentOption?.hasLabel('Enterprise') === true;
        });

        return {
            expirationDate,
            isCityInputDisabled,
            isStateInputVisible,
            isProvinceInputVisible,
            isRegionInputVisible,
            isEnterpriseSelected,
        };
    };

    /**
     * Define the component event hooks.
     */
    const defineEvents = () => {
        /** @type {EventHook<{ data?: Partial<globalThis.Organization.SubscriptionFormData> }>} */
        const submit = createEventHook();
        /** @type {EventHook<{ reason?: string }>} */
        const cancel = createEventHook();
        /** @type {EventHook<void>} */
        const reset = createEventHook();
        /** @type {EventHook<{ id: 'upgrade', event: MouseEvent }>} */
        const upgrade = createEventHook();
        /** @type {EventHook<{ id: 'extend', event: MouseEvent }>} */
        const extend = createEventHook();
        return {
            submit: submit.trigger,
            cancel: cancel.trigger,
            reset: reset.trigger,
            upgrade: upgrade.trigger,
            extend: extend.trigger,
            onSubmit: submit.on,
            onCancel: cancel.on,
            onReset: reset.on,
            onUpgrade: upgrade.on,
            onExtend: extend.on,
        };
    };

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SubscriptionFields',
        components: {
            PageIntro,
            PageDescription,
            FormSection,
            ModalButton,
        },
        props: {
            source: {
                /** @type {Vue.PropType<globalThis.Organization.SubscriptionFormData>} */
                type: Object,
            },
            /** If `true`, the fields are displayed in edit mode. */
            isEditing: {
                type: Boolean,
                default: false,
            },
            /** If `true`, an asynchronous operation is awaiting resolution. */
            isLoading: {
                type: Boolean,
                default: false,
            },
            /** If `true`, display debugging information on the template view. */
            debug: {
                type: Boolean,
                default: process.env.NODE_ENV !== 'production',
            },
        },
        emits: ['cancel', 'submit', 'upgrade', 'extend'],
        setup(props, { emit }) {
            // Destructure the required props.
            const { source, isEditing, isLoading } = toRefs(props);

            // <!-- COMPOSABLES -->
            const regionSelectors = useRegionSelectors();
            const frequencyOptions = useReminderOptions();
            const timezoneOptions = useTimezoneOptions();
            const temperatureOptions = useTemperatureOptions();
            const { isNARAEnabled } = useNARAFeature();

            // <!-- STATE -->
            const { initialData, formData, config, errors } = defineState({
                source,
            });

            // <!-- COMPUTED PROPERTIES -->
            const {
                expirationDate,
                isCityInputDisabled,
                isStateInputVisible,
                isProvinceInputVisible,
                isRegionInputVisible,
            } = defineComputed({
                initialData,
                formData,
                config,
                errors,
                isLoading,
            });

            // <!-- EVENTS -->
            const {
                submit,
                cancel,
                reset,
                upgrade,
                extend,
                onSubmit,
                onCancel,
                onReset,
                onUpgrade,
                onExtend,
            } = defineEvents();

            // <!-- EVENT TRIGGERS -->

            /**
             * Click the save button.
             * @param {import('@formkit/core').FormKitFrameworkContext} context
             */
            const clickSubmitButton = ({ state }) => {
                config.value.validationVisibility = 'live';
                if (state.valid) {
                    submit({ data: formData.value });
                    console.log('Form submitted.');
                } else {
                    console.error('Form is not valid.');
                }
            };

            /**
             * Click the cancel button.
             */
            const clickCancelButton = () => {
                cancel({ reason: 'User canceled.' });
            };

            const clickUpgradeButton = (e) =>
                upgrade({
                    id: 'upgrade',
                    event: e,
                });

            const clickExtendButton = (e) =>
                extend({
                    id: 'extend',
                    event: e,
                });

            // <!-- EVENT LISTENERS -->

            // Callback invoked when `submit` event is raised.
            onSubmit(({ data }) => {
                const target = clone({ ...data });
                emit('submit', { target });
            });

            // Callback invoked when `cancel` event is raised.
            onCancel(({ reason }) => {
                reset(); // Reset the form data on cancellation.
                emit('cancel', { reason });
            });

            // Callback invoked when `reset` event is raised.
            onReset(() => {
                // Initialize the form data back to its original source.
                formData.value = clone({ ...initialData.value });
            });

            onUpgrade((event) => {
                // Open the upgrade modal.
                emit('upgrade', event);
            });

            onExtend((event) => {
                // Open the extend modal.
                emit('extend', event);
            });

            // <!-- LIFECYCLE -->

            // Reset before mounting.
            onBeforeMount(() => {
                reset();
            });

            // <!-- EXPOSE -->
            return {
                // STATE
                formData,
                expirationDate,
                config,
                // VALIDATION
                isValidTimezone,
                // OPTIONS
                frequencyOptions,
                timezoneOptions,
                temperatureOptions,
                countries: regionSelectors.country.options,
                states: regionSelectors.state.options,
                provinces: regionSelectors.province.options,
                // CONDITIONALS
                isCityInputDisabled,
                isRegionInputVisible,
                isStateInputVisible,
                isProvinceInputVisible,
                isNARAEnabled,
                // EVENTS
                clickCancelButton,
                clickSubmitButton,
                clickUpgradeButton,
                clickExtendButton,
            };
        },
    });
</script>

<style lang="scss" scoped>
    // TODO: Generated by VSCode snippet.
</style>
