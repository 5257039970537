<template>
    <Panel>
        <template #header>
            <div class="flex flex-row justify-between">
                <div class="text-2xl text-black font-bold">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #default>
            <transition
                :appear="true"
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0"
                enter-to-class="transform opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="transform opacity-100"
                leave-to-class="transform opacity-0"
            >
                <UserFields
                    :source="source"
                    :isEditing="isEditing"
                    :isLoading="isLoading"
                    :debug="debug"
                    @cancel="cancel"
                    @submit="submit"
                />
            </transition>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import UserFields from '~UserManager/components/UserFields.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AddUserForm',
        components: {
            Panel,
            UserFields,
        },
        props: {
            source: {
                /** @type {Vue.PropType<Omit<globalThis.User.Target, 'id'>>} */
                type: Object,
            },
            isEditing: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['submit', 'cancel'],
        setup(props, { emit }) {
            /** @type {String} Form header. */
            const title = `Add User`;

            /** @type {(event: { reason?: string }) => void } */
            const cancel = (event) => {
                emit('cancel', event);
            };

            /** @type {(event: { target?: Omit<globalThis.User.Target, 'id'> }) => void } */
            const submit = (event) => {
                emit('submit', event);
            };

            return {
                title,
                cancel,
                submit,
            };
        },
    });
</script>
