// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Subscription } from '@/models/v2/subscriptions';
import { Organization } from '@/models/v2/organizations';
import { Plan } from '@/models/v2/plans';

// <!-- ROUTES -->

/** @type {Readonly<globalThis.Subscription.Routes>} */
const URI = {
    index: () => `subscriptions`,
    show: (id) => `subscriptions/${id}`,
    create: () => `subscriptions`,
    update: (id) => `subscriptions/${id}`,
    delete: (id) => `subscriptions/${id}`,
    organization: (id) => `subscriptions/${id}/organization`,
    plan: (id) => `subscriptions/${id}/plan`,
};

// <!-- ENDPOINTS -->

/**
 * Get all subscriptions available in the database.
 * @type {globalThis.Subscription.API.FetchIndex}
 */
export const fetchSubscriptions = async (config) => {
    try {
        /** @type {globalThis.Subscription.Response.FetchedIndex} */
        const response = await axios().get(URI.index(), config);
        const payloads = response.data.data.map(Subscription.Payload.create);
        const models = payloads.map(Subscription.Model.fromPayload);
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get an subscription by its identifier.
 * @type {globalThis.Subscription.API.FetchResource}
 */
export const fetchSubscriptionById = async (request, config) => {
    try {
        /** @type {globalThis.Subscription.Response.FetchedResource} */
        const response = await axios().get(URI.show(request.id), config);
        const payload = new Subscription.Payload(response.data.subscription);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Create a single subscription.
 * @type {globalThis.Subscription.API.CreateResource}
 */
export const createSubscription = async (request, config) => {
    try {
        /** @type {globalThis.Subscription.Response.CreatedResource} */
        const response = await axios().post(URI.create(), request, config);
        const payload = new Subscription.Payload(response.data.subscription);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Update a single subscription by id.
 * @type {globalThis.Subscription.API.UpdateResource}
 */
export const updateSubscriptionById = async (request, config) => {
    try {
        /** @type {globalThis.Subscription.Response.UpdatedResource} */
        const response = await axios().patch(
            URI.update(request.id),
            request,
            config
        );
        const payload = new Subscription.Payload(response.data.subscription);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Delete an subscription by id.
 * @type {globalThis.Subscription.API.DeleteResource}
 */
export const deleteSubscriptionById = async (request, config) => {
    try {
        /** @type {globalThis.Subscription.Response.DeletedResource} */
        const response = await axios().delete(URI.delete(request.id), config);
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Get a subscription organization.
 * @type {globalThis.Subscription.API.FetchOrganization}
 */
export const fetchSubscriptionOrganization = async (request, config) => {
    try {
        /** @type {globalThis.Subscription.Response.FetchedOrganization} */
        const response = await axios().get(
            URI.organization(request.id),
            config
        );
        const payload = new Organization.Payload(response.data?.organization);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Get a subscription plan.
 * @type {globalThis.Subscription.API.FetchPlan}
 */
export const fetchSubscriptionPlan = async (request, config) => {
    try {
        /** @type {globalThis.Subscription.Response.FetchedPlan} */
        const response = await axios().get(URI.plan(request.id), config);
        const payload = new Plan.Payload(response.data?.subscription_level);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/** @type {Readonly<globalThis.Subscription.API>} */
export default {
    fetchSubscriptions,
    fetchSubscriptionById,
    createSubscription,
    updateSubscriptionById,
    deleteSubscriptionById,
    fetchSubscriptionOrganization,
    fetchSubscriptionPlan,
};
